<template>
    <footer id="footer" class="footer-wrapper" :class="{ footer_iframe: this.$route.name == 'Livevh_vh'}">
        <!-- <div class="banner_live_partner">
            <a href="javascript:;"><img src="../assets/images/okvip.png" alt="" style="width:100%;"></a>
        </div> -->
        <div class="wrapper_fter_end">
            <div class="container ner_responesize">
                <div class="preview_okvip">
                    <div class="img_f8bet">
                        <img src="../assets/images/logo.png" alt="">
                    </div>
                    <div class="textfooter">
                        <p v-if="!readMore[index]">{{ content.substring(0, 550) + '[...]' }} <span @click="showMore(index)" class="spanmore">Xem thêm</span></p>
                        <p v-if="readMore[index]">{{ content }} <span @click="showLess(index)" class="spanless">Thu gọn</span></p>
                    </div>
                </div>
                <h2 class="title_infomation">Thông tin</h2>
                <div class="row">
                    <div class="col-md-3 col-sm-3 col-xs-3 item_footer">
                        <div class="menu_footer_item">
                            <p><a :href="link_banner" target="_blank">Điều khoản và điều kiện</a></p>
                            <p><a :href="link_banner" target="_blank">Giới thiệu về LUAMACHTV</a></p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3 item_footer">
                        <div class="menu_footer_item">
                            <p><a :href="link_banner" target="_blank">Chơi có trách nhiệm</a></p>
                            <p><a :href="link_banner" target="_blank">Miễn trách nhiệm</a></p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3 item_footer">
                        <div class="menu_footer_item">
                            <p><a :href="link_banner" target="_blank">Quyền riêng tư tại LUAMCHTV</a></p>
                            <p><a :href="link_banner" target="_blank">Những câu hỏi thường gặp</a></p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3 item_footer">
                        <div class="menu_footer_item">
                            <p><a :href="link_banner" target="_blank">Hướng dẫn nạp tiền</a></p>
                            <p><a :href="link_banner" target="_blank">Hướng dẫn rút tiền</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright_f8">
                <p>Copyright © LUAMACHTV Reserved</p>
            </div>
        </div>
        
        <div class="parent">
            <a :href="url_linkredirect" target="_blank">
                <Vue3DraggableResizable
                    :initW="175"
                    :initH="43"
                    v-model:x="x"
                    v-model:y="y"
                    v-model:w="w"
                    v-model:h="h"
                    v-model:active="active"
                    :draggable="true"
                    :resizable="false"
                >
                <span class="text_btndk_ab">
                    <img src="../assets/images/icon/login.png" alt=""> Đăng ký +88k
                </span>
                </Vue3DraggableResizable>
            </a>
        </div>
    </footer>
</template>

<script>
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on('click', '.close_banner', function() {
        $('.banner_fixed_center').css('display', 'none');
    });
    $(document).on('click', '.img_close_parent', function() {
        $('.parent').hide();
        return false;
    });
})
export default {
    name: 'Footer_Vaohang',
    data () {
        return {
            readMore: [false],
            content: 'LUAMACHTV là một nhà cái có giấy phép cá cược trực tuyến hợp pháp do Isle of Man và Khu kinh tế Cagayan and Freeport cấp. Với bề dày kinh nghiệm và danh tiếng phục vụ hơn 10 triệu người chơi, LUAMACHTV đã và đang khẳng định vị thế của mình trên thị trường game trực tuyến .Với tư cách là một công ty trò chơi trực tuyến trực thuộc Tổng bộ Liên minh LUAMACHTV có trụ sở tại Luân Đôn, Anh, sở hữu đội ngũ nhân tài chuyên nghiệp đông đảo cung cấp sản phẩm phục vụ chất lượng cao. LUAMACHTV đảm bảo không tiết lộ thông tin cá nhân khách hàng cho bất kỳ bên thứ ba nào , sử dụng tiêu chuẩn mã hoá dữ liệu ở mức cao nhất. Tất cả thông tin cá nhân đều được thông qua hệ thống bảo mật - Secure Socket (Chuẩn mã hóa SS 128-bit), đồng thời được bảo vệ trong môi trường quản lý an toàn đảm bảo không thể truy cập từ các khu vực mạng công cộng. Tất cả dữ liệu ra vào đều bị hạn chế, giám sát nghiêm ngặt và quản lý chặt chẽ nhằm mang đến cho người chơi trải nghiệm người dùng an toàn tuyệt đối.',
            linkLive: '',
            linkCuoc: '',
            active: true
        }
    },
    mounted () {
        this.socket.on('chat:chat_live',function(result){
            // console.log('111111', result.data);
            var id_append = $('input[name="id-chat"]').val();
            let imgGift = require('@/assets/images/cic.gif')
            let imgReal = require('@/assets/images/icon/tick.png')
            if(id_append == result.data.id_match){
                // let checkname = result.data.fullname.substr(0, 3).toLowerCase();
                // var class_blv = ''
                // if(checkname == 'blv'){
                //     class_blv = 'active_color'
                // }else{
                //     class_blv = ''
                // }
                if(result.data.sticker == null || result.data.sticker == ''){
                    this.stickerDV = `<div class="body content_chat_edit">${result.data.content}</div>`;
                }else{
                    this.stickerDV = `<div class="imgsticker"><img src="https://adminsa88.luamachtv.vip/${result.data.sticker}" alt=""></div>`;
                }

                if(result.data.real_mess != 0){
                    this.people = `<img class="img_people" src="${imgReal}" alt="">`
                }else{
                    this.people = ''
                }

                if(result.data.level != 0){
                    this.levels = 'Lv' + result.data.level
                }else{
                    this.levels = ''
                }
                
                this.str = `<div class="flex_chat_icon msg msg-${result.data.id}">
                            <div class="img_random_chat">
                                <img src="${imgGift}" alt="">
                            </div>
                            <div class="content_text_chats">
                                <div class="name_time_chat">
                                    <div class="dtxt" data-time="${result.data.created_at}">${timeAgo(result.data.created_at)}</div>
                                    <div class="nme">${result.data.fullname} ${this.people} <span class="level level${this.levels}">${this.levels}</span></div>
                                </div>
                                ${this.stickerDV}
                            </div>
                        </div>`;
                $(`#${id_append}`).append(this.str);
                var hg = $('.chat-text').prop('scrollHeight');
                $('.chat-text').animate({ scrollTop: hg+100 },500);
            }
            else{
                $(`.msg-${result.data.id}`).remove();
            }

            // this.data_time = $(`#${result.data.url}`).find('.dtxt');
            // $.each(this.data_time, function(){
            //     this.time = $(this).data('time');
            //     $(this).html( timeAgo(this.time) );
            // });
        });
        this.socket.on('chat:message_macth_api',function(result){
            // console.log('33333', result);
            var id_append = $('input[name="id-chat"]').val();
            let imgGift = require('@/assets/images/cic.gif')
            // let checkname = result.message.name.substr(0, 3).toLowerCase();
            // var class_blv = ''
            // if(checkname == 'blv'){
            //     class_blv = 'active_color'
            // }else{
            //     class_blv = ''
            // }
            if(result.message.sticker !== null){
                this.stickerDV = `<div class="imgsticker"><img src="https://adminsa88.luamachtv.vip/${result.message.sticker}" alt=""></div>`;
            }else{
                this.stickerDV = `<div class="body content_chat_edit">${result.message.content}</div>`;
            }
            this.str = `<div class="flex_chat_icon msg msg-${result.message.id}">
                        <div class="img_random_chat">
                            <img src="${imgGift}" alt="">
                        </div>
                        <div class="content_text_chats">
                            <div class="name_time_chat">
                                <div class="nme">${result.message.fullname}</div>
                            </div>
                            ${this.stickerDV}
                        </div>
                    </div>`;
            $(`#${id_append}`).append(this.str);
            var hg = $('.chat-text').prop('scrollHeight');
            $('.chat-text').animate({ scrollTop: hg+100 },500);
        });
        
        function timeAgo(str_date) {
            var d = new Date();
            var UTCsecondsNow = (d.getTime() + d.getTimezoneOffset() * 60 * 1000);

            var date = new Date(str_date);
            var UTCseconds = (date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            var diff = UTCsecondsNow - UTCseconds;
            var tense = 'trước';
            if (diff < 0) {
                tense = 'sau';
                diff = Math.abs(diff);
            }
            if (diff === 0) return 0;
            var years = singular(Math.round(diff / 31557600000), 'năm');
            if (years)
            return years + tense;
            var months = singular(Math.round(diff / 2592000000), 'tháng');
            if (months)
            return months + tense;
            var days = singular(Math.round(diff / 86400000), 'ngày');
            if (days)
            return days + tense;
            var hours = singular(Math.round(diff / 3600000), 'giờ');
            if (hours)
            return hours + tense;
            var mins = singular(Math.round(diff / 60000), 'phút');
            if (mins)
            return mins + tense;
            var secs = singular(Math.round(diff / 1000), 'giây');
            if (secs){
                return secs + tense;
            }
            else return 'Vừa xong';
        }
        function singular(num, str) {
            if (num >= 1) {
                return num + ' ' + str + ' ';
            }
            return '';
        }
    },
    created () {
        this.getInfoLink()
    },
    methods: {
        showMore(index) {
            this.readMore[index] = true;
        },
        showLess(index) {
            this.readMore[index] = false;
        },
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkLive = response.data.data.list_data.live
                this.linkCuoc = response.data.data.list_data.cuoc
            })
        },
    }
}
</script>
<style>
</style>
