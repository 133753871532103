<template>
    <div class="container page_bxh-vh content-margin maxwidth_container">
        <div class="wrapper_bxh_blv">
            <h2 class="title_updating">Đang cập nhật</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IdolLive_vh',
    data () {
        return {
            
        }
    },
    components: {
        
    },
    created () {
        
    },
    mounted () {
        
    },
    beforeUnmount () {
        
    },
    methods: {
        
    }
}
</script>

<style scoped>
</style>
