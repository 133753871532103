<template>
    <div class="root_ltd_list">
        <div id="content" class="content-area page-wrapper container maxwidth_container content-margin root_ltdedit_mb" role="main">
            <div class="row row-main main_page_edit">
                <div class="large-12 col ltd_container">
                    <div class="col-inner">
                        <div class="wp-schedule wrapper_match_calenda">
                            <div class="item_tournament">
                                <div class="wp_item_tournament">
                                    <div class="match_tournament" v-for="(item, indexs) in listMatchAll" :key="indexs">
                                        <div class="div_02">{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</div>
                                        <div class="div_04">
                                            <span class="name_home_ltd">{{ replaceName(item.result.match.homeTeam.slug) }}</span>
                                            <span class="vs_ltd">
                                                <img :src="item.result.match.homeTeam.logo" class="img-logolt" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" class="img-logolt" alt="" v-else>
                                                <b>-</b> 
                                                <img :src="item.result.match.awayTeam.logo" class="img-logolt" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" class="img-logolt" alt="" v-else>
                                            </span>
                                            <span class="name_away_ltd">{{ replaceName(item.result.match.awayTeam.slug) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
// import $ from 'jquery'
export default {
    name: "Lichthidau_vh",
    data() {
        return {
            listMatchAll: []
        }
    },
    created () {
        // this.getDataCalendaMatch()
        this.getlistAllMatch()
    },
    methods: {
        format_date(value){
            if (value) {
                return moment(String(value)).format('HH:mm DD/MM')
            }
        },
        formatTimeSramp (str) {
            const timestamp = str * 1000;
            const date = new Date(timestamp);
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            // const yyyy = date.getFullYear();
            const hh = String(date.getHours()).padStart(2, '0');
            const ii = String(date.getMinutes()).padStart(2, '0');
            // const ss = String(date.getSeconds()).padStart(2, '0');
            const formattedDate = `${dd}/${mm} ${hh}:${ii}`;
            return formattedDate;
        },

        // getDataCalendaMatch () {
        //     const postData = {
        //         pageNum: 1,
        //         pageSize: 100,
        //         query: {
        //             sportId: 1,
        //         },
        //     }
        //     this.axios.post(this.api_listcalendaMatch, postData, {
        //         headers: {
        //             'language-config': 'en'
        //         }
        //     }).then((response) => {
        //         this.listLtd = response.data.data.records
        //     })
        // },
        getlistAllMatch () {
            this.axios.get(this.api_matchCola).then((response) => {
                this.listMatchAll = response.data.data
            })
        },
        convertDateStame (d) {
            let arr = d.split(' ')
            let ngaythang = arr[0]
            let arrNt = ngaythang.split('-')
            let giophut = arr[1]
            let arrGp = giophut.split(':')
            return arrNt[2] + '/' + arrNt[1] + ' ' + arrGp[0] + ':' + arrGp[1]
        },
        convertDatetime(d) {
            let gt7 = 7 * 3600
            let timeStamp_change = Number(d) + gt7
            return new Date(timeStamp_change * 1000).toISOString().slice(0, 19).replace('T', ' ')
        },
        replaceName (str) {
            return str.replaceAll('-', ' ')
        },
    }
}
</script>
<style>
</style>
