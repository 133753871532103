import { createRouter, createWebHistory } from 'vue-router'

import Home_vaohang from '@/components/Home.vue'
import Lichthidau_vh from '@/components/Lichthidau.vue'
import Kqbd_vh from '@/components/Kqbd'
import Bxh_vh from '@/components/Bxh.vue'
import Live_vh from '@/components/Live.vue'
import Ratio_vh from '@/components/Ratio.vue'
import IdolLive_vh from '@/components/IdolLive.vue'
import Khuyenmai_vh from '@/components/Khuyenmai.vue'

const routes = [
    {
        path: '/',
        name: 'Home_vaohang',
        component: Home_vaohang
    },
    {
        path: '/lich-thi-dau',
        name: 'Lichthidau_vh',
        component: Lichthidau_vh
    },
    {
        path: '/kqbd',
        name: 'Kqbd_vh',
        component: Kqbd_vh
    },
    {
        path: '/bxh',
        name: 'Bxh_vh',
        component: Bxh_vh
    },
    {
        path: '/live/:id/:check/:tab/:url',
        name: 'Live_vh',
        component: Live_vh
    },
    {
        path: '/ratio',
        name: 'Ratio_vh',
        component: Ratio_vh
    },
    {
        path: '/idol-live',
        name: 'IdolLive_vh',
        component: IdolLive_vh
    },
    {
        path: '/khuyen-mai',
        name: 'Khuyenmai_vh',
        component: Khuyenmai_vh
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router