<template>
    <div class="wrapper_bgr_edit">
        <div class="wrapper_live_bgr">
            <div class="container maxwidth_container pading_three_div">
                <div class="live_room live_room_bgr" v-if="listMatchApi.length > 0">
                    <div class="root_liveRoom">
                        <div class="row_wp">


                            <div class="tab_itemlive">
                                <div class="wp_item_tablive">
                                    <swiper
                                        :slidesPerView="3"
                                        :direction="'vertical'"
                                        :navigation="true"
                                        :modules="modules"
                                    >
                                        <swiper-slide v-bind:class="'tabhome tab' + index" v-on:click="changeTab1('tab' + index)" v-for="(item, index) in listMatchApi.slice(0,10)" :key="index">
                                            <div class="root_tablive_itemhome">
                                                <div class="item_soccer" :class="{active: index == 0}">
                                                    <!-- <div class="tournament">
                                                        <h2>{{ replaceName(item.result.match.competition.slug) }}</h2>
                                                        <img src="../assets/images/liv.png" alt="">
                                                    </div> -->
                                                    <div class="content_soccer">
                                                        <div class="home_soccer">
                                                            <img :src="item.result.match.homeTeam.logo" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                            <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" alt="" v-else>
                                                            <h3>{{ replaceName(item.result.match.homeTeam.slug) }}</h3>
                                                        </div>
                                                        <div class="btn_info_soccer">
                                                            <img src="../assets/images/icon/vs.png" alt="">
                                                            <p>{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</p>
                                                            <p class="blv_ddr" v-if="typeof item?.result?.match?.homeScores !== 'undefined'">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</p>
                                                        </div>
                                                        <div class="away_soccer">
                                                            <img :src="item.result.match.awayTeam.logo" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                            <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" alt="" v-else>
                                                            <h3>{{ replaceName(item.result.match.awayTeam.slug) }}</h3>
                                                        </div>
                                                        <div class="blv_tablivehome">
                                                            <p>
                                                                <img src="../assets/images/icon/user.png" alt="" class="iconname_blv">
                                                                <span class="blv_tabhomelv" v-if="typeof item?.blv !== 'undefined'">{{ item.blv }}</span>
                                                                <span class="blv_tabhomelv" v-else>BLV Nhà Đài</span>
                                                                <img src="../assets/images/icon/live.png" alt="" class="live_tabicon">
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    </swiper>
                                </div>
                            </div>


                            <div class="wrapper_liveroom">
                                <div class="video_live_room" v-for="(lives, index) in listMatchApi.slice(0,10)" v-bind:key="index">
                                    <div v-if="tabSl === 'tab' + index">
                                        <div class="videohome_lf bgr_video">
                                            <div class="video_vietnam">
                                                <div class="video_edit">
                                                    <vue3-video-player
                                                        autoplay
                                                        :core="HLSCore"
                                                        :src="lives.result.match.videoUrl"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, 'videolivecam')"
                                                        id="videolivecam"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="tab_itemlive">
                                <div class="wp_item_tablive">
                                    <p class="titlePlayHome">Đang phát sóng <img src="../assets/images/live_1.gif" alt=""></p>
                                    <div v-bind:class="'tabhome tab' + index" v-on:click="changeTab1('tab' + index)" v-for="(item, index) in listMatchApi.slice(0,4)" :key="index">
                                        <div class="item_soccer" :class="{active: index == 0}">
                                            <div class="tournament">
                                                <h2>{{ replaceName(item.result.match.competition.slug) }}</h2>
                                                <img src="../assets/images/liv.png" alt="">
                                            </div>
                                            <div class="content_soccer">
                                                <div class="home_soccer">
                                                    <img :src="item.result.match.homeTeam.logo" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                    <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" alt="" v-else>
                                                    <h3>{{ replaceName(item.result.match.homeTeam.slug) }}</h3>
                                                </div>
                                                <div class="btn_info_soccer">
                                                    <img src="../assets/images/play.png" alt="">
                                                    <p>{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</p>
                                                    <p class="blv_ddr" v-if="typeof item?.result?.match?.homeScores !== 'undefined'">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</p>
                                                </div>
                                                <div class="away_soccer">
                                                    <img :src="item.result.match.awayTeam.logo" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                    <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" alt="" v-else>
                                                    <h3>{{ replaceName(item.result.match.awayTeam.slug) }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->


                        </div>
                    </div>
                </div>
                <div class="live_room" v-else>
                    <div class="root_liveRoom">
                        <div class="row_wp">
                            <div class="tab_itemlive">
                                <div v-bind:class="'tabhome tab0'" v-on:click="changeTab1('tab0')">
                                    <div class="contentKcong" :class="{active: tabSl == 'tab0'}">
                                        <img src="../assets/images/t1.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="wrapper_liveroom">
                                <div class="video_live_room">
                                    <div v-if="tabSl === 'tab0'">
                                        <div class="videohome_lf bgr_video">
                                            <div class="video_vietnam">
                                                <div class="video_edit">
                                                    <vue3-video-player
                                                        autoplay
                                                        :core="HLSCore"
                                                        :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                                        title=""
                                                        resolution="408p"
                                                        :view-core="viewCore.bind(null, 'videolivecamtvc')"
                                                        id="videolivecamtvc"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container content-margin maxwidth_container pading_three_div">
            <div class="tab_blv_home">
                <Carousel :wrap-around="false" :autoplay="5000" :breakpoints="breakpoints">
                    <Slide v-for="(item, indexs) in listblv" :key="indexs">
                        <div class="item_list_blv">
                            <div class="blv_stoke_in">
                                <div class="img_list_blv">
                                    <img :src="url_linkimg + item.src_image" alt="">
                                </div>
                                <div class="name_list_blv">
                                    <p>{{ item.name }}</p>
                                </div>
                            </div>
                        </div>
                    </Slide>
                    <template #addons>
                        <!-- <pagination /> -->
                        <navigation />
                    </template>
                </Carousel>
            </div>
            <div class="wrapper_match_home">
                <div class="tab_match_home">
                    <ul>
                        <li :class="{active: tabSlMatch == 'tab1'}" v-on:click="changeTabMatch('tab1')"><img src="../assets/images/icon/bongda.png" alt=""> Bóng đá <img src="../assets/images/live.gif" alt="" class="imglive_tab"><span>{{ listMatchApiAll.length }}</span></li>
                        <li :class="{active: tabSlMatch == 'tab2'}" v-on:click="changeTabMatch('tab2')"><img src="../assets/images/icon/bongro.png" alt=""> Bóng rổ <span>0</span></li>
                        <li :class="{active: tabSlMatch == 'tab3'}" v-on:click="changeTabMatch('tab3')"><img src="../assets/images/icon/esport.png" alt=""> E sport <span>0</span></li>
                        <li :class="{active: tabSlMatch == 'tab4'}" v-on:click="changeTabMatch('tab4')"><img src="../assets/images/icon/bida.png" alt=""> Bida <span>0</span></li>
                        <li :class="{active: tabSlMatch == 'tab5'}" v-on:click="changeTabMatch('tab5')"><img src="../assets/images/icon/casino.png" alt=""> Casino <span>0</span></li>
                        <li :class="{active: tabSlMatch == 'tab6'}" v-on:click="changeTabMatch('tab6')"><img src="../assets/images/icon/duaxe.png" alt=""> Đua xe <span>0</span></li>
                        <li :class="{active: tabSlMatch == 'tab7'}" v-on:click="changeTabMatch('tab7')"><img src="../assets/images/icon/vothuat.png" alt=""> Võ thuật <span>0</span></li>
                    </ul>
                </div>
                <div class="wrapper_match_tabhome">
                    <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab1'">
                        <div class="tab-content">
                            <div class="allmatch">
                                <div class="row">
                                    <div class="col-md-3 col-sm-3 col-xs-4 item-list_edit" v-for="(item, index) in listMatchApiAll" v-bind:key="index">
                                        <div class="item-list">
                                            <router-link class="nav-item" v-bind:to="{ name: 'Live_vh', params: { id: item.result.match.id, check: item.result.match.matchStatus, tab: 1, url: item.result.match.homeTeam.slug + '-vs-' + item.result.match.awayTeam.slug + '-' +  item.result.match.id } }">
                                                <div class="content_match_edit">
                                                    <div class="giaidau_ngaydau">
                                                        <div class="date-match">
                                                            <p>
                                                                <span class="nonemb" v-if="replaceName(item.result.match.competition.slug).length < 10">{{ replaceName(item.result.match.competition.slug) }}</span>
                                                                <span class="nonemb" v-else>{{ replaceName(item.result.match.competition.slug).substring(0, 10) }}</span>
                                                                <span class="img_livemacth"><img src="../assets/images/live_1.gif" alt="" v-if="item.result.match.matchStatus == 2"></span>
                                                                <span class="date-lt">{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="center-list">
                                                        <div class="name_logo_doi">
                                                            <img :src="item.result.match.homeTeam.logo" class="img-logolt" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                            <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" class="img-logolt" alt="" v-else>
                                                            <span class="name-lt">{{ replaceName(item.result.match.homeTeam.slug) }}</span>
                                                        </div>
                                                        <div class="tyso_trandau">
                                                            <span class="nenxanh" v-if="item.result.match.matchStatus == 2 && typeof item?.result?.match?.homeScores !== 'undefined'">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</span>
                                                            <span class="khongnen" v-else>VS</span>
                                                        </div>
                                                        <div class="name_logo_doi">
                                                            <img :src="item.result.match.awayTeam.logo" class="img-logolt" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                            <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" class="img-logolt" alt="" v-else>
                                                            <span class="name-lt">{{ replaceName(item.result.match.awayTeam.slug) }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="nameblv_match">
                                                        <span class="blv-class" v-if="typeof item?.blv !== 'undefined'"><img :src="url_linkimg + item.image_blv" alt=""> {{ item.blv }}</span>
                                                        <span class="blv-class" v-else><img src="../assets/images/imgblv.png" alt=""> BLV Nhà Đài</span>

                                                        <span class="sapdienra" v-if="item.result.match.matchStatus == 2">Đang diễn ra</span>
                                                        <span class="sapdienra" v-else>Sắp diễn ra</span>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab2'">
                        <div class="tab-content">
                            <div class="allmatch">
                                <h2 class="title_update_match">Đang cập nhật</h2>
                            </div>
                        </div>
                    </div>
                    <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab3'">
                        <div class="tab-content">
                            <div class="allmatch">
                                <h2 class="title_update_match">Đang cập nhật</h2>
                            </div>
                        </div>
                    </div>
                    <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab4'">
                        <div class="tab-content">
                            <div class="allmatch">
                                <h2 class="title_update_match">Đang cập nhật</h2>
                            </div>
                        </div>
                    </div>
                    <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab5'">
                        <div class="tab-content">
                            <div class="allmatch">
                                <h2 class="title_update_match">Đang cập nhật</h2>
                            </div>
                        </div>
                    </div>
                    <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab6'">
                        <div class="tab-content">
                            <div class="allmatch">
                                <h2 class="title_update_match">Đang cập nhật</h2>
                            </div>
                        </div>
                    </div>
                    <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab7'">
                        <div class="tab-content">
                            <div class="allmatch">
                                <h2 class="title_update_match">Đang cập nhật</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_bxh_blv" style="display:none !important;">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 left_bxh_home">
                        <DetailBxh_vh />
                    </div>
                </div>
            </div>
            <div class="root_ratio_home ratio_tyle" style="display:none !important;">
                <Tylekeo_vh />
            </div>
            <!-- modal euro -->
            <div id="modal-example" class="modal-euro">
                <div class="overlay" v-if="showModal_euro" @click="showModal_euro = false"></div>
                <div class="modal" v-if="showModal_euro">
                    <div class="wp-modal-euro">
                        <div class="header-modal">
                            <button class="close-modal_euro" @click="showModal_euro = false"><img src="../assets/images/close_euro.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div class="root_form_euro">
                                <img src="../assets/images/logo_euro.gif" alt="" class="logo_euro_top">
                                <div class="img_bgr_euro">
                                    <img src="../assets/images/md.png" alt="">
                                </div>
                                <form action="" method="post" id="frm_euro" @submit="sendInfoModal">
                                    <div class="input_modal_euro">
                                        <input type="text" v-model="fullname" placeholder="Họ và tên">
                                        <img src="../assets/images/hvt.png" alt="" class="icon_euro">
                                    </div>
                                    <div class="input_modal_euro">
                                        <input type="text" v-model="tell" placeholder="Số điện thoại">
                                        <img src="../assets/images/tell.png" alt="" class="icon_euro">
                                    </div>
                                    <div class="btn_modal_euro">
                                        <button type="submit"><img src="../assets/images/euro.gif" alt=""></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-example" class="modal-euro">
                <div class="overlay" v-if="showModal_euro1" @click="showModal_euro1 = false"></div>
                <div class="modal" v-if="showModal_euro1">
                    <div class="wp-modal-euro">
                        <div class="header-modal">
                            <button class="close-modal_euro" @click="showModal_euro1 = false"><img src="../assets/images/close_euro.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div class="root_form_euro">
                                <img src="../assets/images/logo_euro.gif" alt="" class="logo_euro_top">
                                <div class="img_bgr_euro">
                                    <img src="../assets/images/md1.png" alt="">
                                </div>
                                <div class="noti_euru">
                                    <img src="../assets/images/noti.png" alt="">
                                    <a :href="linkCuoc" target="_blank"><img src="../assets/images/tt.png" alt=""></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end modal euro -->
        </div>
    </div>
</template>

<script>
import DetailBxh_vh from '@/components/DetailBxh'
import Tylekeo_vh from '@/components/Tylekeo.vue'
import HLSCore from '@cloudgeek/playcore-hls'
import Swal from 'sweetalert2'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import $ from 'jquery'
import SocketIO from 'socket.io-client'
$( document ).ready(function() {
    $(document).on("click",".date_ratio_home label",function() {
        $('.date_ratio_home label').parent().removeClass('active');
        $(this).parent().addClass('active');
    });
    $(document).on("click",".tab_itemlive .tabhome",function() {
        $('.tab_itemlive .tabhome .item_soccer').removeClass('active');
        $(this).find('.item_soccer').addClass('active');
    });
});

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation as SwiperNavigation  } from 'swiper/modules';
export default {
    name: 'Home_vaohang',
    setup() {
		return {
			breakpoints: {
				0: {
					itemsToShow: 2,
					snapAlign: "start"
				},
				768: {
					itemsToShow: 3,
					snapAlign: "start"
				},
				1024: {
					itemsToShow: 4,
					snapAlign: "start"
				},
                1280: {
					itemsToShow: 6,
					snapAlign: "start"
				},
			},
            modules: [SwiperNavigation],
		};
	},
    data () {
        return {
            tabSlMatch: 'tab1',
            tabSelected: '',
            listItemdate1: [],
            tabs: '',
            liveRoom: [],
            country: 'Vietnam',
            players: {},
            volume: 90,
            HLSCore,
            hlight: [],
            listPlayMatch: [],
            check: '',
            checke: '',
            dataRatio: [],
            dataRatio1: [],
            dataRatio2: [],
            dataRatio3: [],
            data_name_ratio: [],
            data_name_ratio1: [],
            data_name_ratio2: [],
            data_name_ratio3: [],
            date1: '',
            date2: '',
            date3: '',
            date4: '',
            month1: '',
            month2: '',
            month3: '',
            month4: '',
            datechange: '',
            listhot: [],
            rt: [],
            rt1: [],
            rt2: [],
            rt3: [],
            rati1: [],
            rati3: [],
            rati5: [],
            rati7: [],
            rati8: [],
            rati15: [],
            listIcon: [],
            name: '',
            content_chat: '',
            stickerDV: '',
            listF8: [],
            listF8s: [],
            editorConfig: {
                toolbar: [ 'link', 'italic', 'bold'],
                placeholder: "Nội dung",
            },
            linkKeo: '',
            linkCuoc: '',
            linkTele: '',
            linkZalo: '',
            linkLive: '',
            listblv: [],
            listFilm: [],
            tabSl: 'tab0',
            listMatchApi: [],
            listMatchApiAll: [],
            orther: [],
            checkClickAdv: 0,
            mutedTvc: 0,

            listNhaDai: [],
            ListHot: [],
            ListMatchBlv: [],

            showModal_euro: false,
            showModal_euro1: false,
            fullname: '',
            tell: '',
            others: []
        }
    },
    components: {
        Carousel,
        Slide,
        // Pagination,
        Navigation,
        DetailBxh_vh,
        Tylekeo_vh,
        Swiper,
        SwiperSlide,
    },
    created () {
        this.getData()
        this.getlocation()
        this.getDataPlayMatch ()
        // this.getDataRatio()
        this.listIconChat()
        // setInterval(this.getDataRoomLive, 30000);
        this.getInfoLink()
        this.getListblv()
        this.getlistApiMatch()
        setTimeout(() => {
            $('.nextvideo').show()
        }, 5000);
    },
    mounted () {
        let ss = sessionStorage.getItem("session");
        if(ss == null){
            var title_ss = 'user0';
            var num_ran = Math.floor(Math.random() * 1000000000);
            var session = title_ss + num_ran
            sessionStorage.setItem("session", session);
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const today = new Date();
        const tomorrow1 = new Date();
        const tomorrow2 = new Date();
        const tomorrow3 = new Date();
        const tomorrow4 = new Date();
        
        var d1 = new Date(tomorrow1.setDate(today.getDate())).toLocaleDateString("en-US")
        var d2 = new Date(tomorrow2.setDate(today.getDate() + 1)).toLocaleDateString("en-US")
        var d3 = new Date(tomorrow3.setDate(today.getDate() + 2)).toLocaleDateString("en-US")
        var d4 = new Date(tomorrow4.setDate(today.getDate() + 3)).toLocaleDateString("en-US")

        this.date1 = d1.split('/')[1]
        if(this.date1 < 10){
            this.date1 = '0' + this.date1
        }
        this.date2 = d2.split('/')[1]
        if(this.date2 < 10){
            this.date2 = '0' + this.date2
        }
        this.date3 = d3.split('/')[1]
        if(this.date3 < 10){
            this.date3 = '0' + this.date3
        }
        this.date4 = d4.split('/')[1]
        if(this.date4 < 10){
            this.date4 = '0' + this.date4
        }
        this.month1 = d1.split('/')[0]
        this.month2 = d2.split('/')[0]
        this.month3 = d3.split('/')[0]
        this.month4 = d4.split('/')[0]

        // this.axios.get(this.api_listmatchdate).then((response) => {
        //     let lr = response.data.data
        //     let lr1 = Object.values(lr)[0];
        //     let datart = lr1.slice(0,1)
        //     setTimeout(() => {
        //         $.ajax({
        //             url: 'https://f8betchat.com/api/chat/list-message',
        //             type: "GET",
        //             data: { url : datart[0].url },
        //             dataType: 'json',
        //             success : function( response ) {
        //                 $.each(response.data, function(){
        //                     let checkname = this.name.substr(0, 3).toLowerCase();
        //                     var class_blv = ''
        //                     if(checkname == 'blv'){
        //                         class_blv = 'active_color'
        //                     }else{
        //                         class_blv = ''
        //                     }
        //                     if(this.sticker !== null){
        //                         this.stickerDV = `<div class="imgsticker"><img src="https://f8betchat.com/${this.sticker}" alt=""></div>`;
        //                     }else{
        //                         this.stickerDV = `<div class="body content_chat_edit ${class_blv}">${this.content}</div>`;
        //                     }
        //                     // <span class="pic Empty"></span>
        //                     this.str = `<div class="flex_chat_icon msg msg-${this.id}">
        //                                 <div class="img_random_chat">
        //                                     <img src="https://f8betchat.com/images/cic.gif" alt="">
        //                                 </div>
        //                                 <div class="content_text_chats">
        //                                     <div class="name_time_chat">
        //                                         <div class="dtxt" title="23 Nov 22, 02:10 AM" data-time="${this.created_at}">${timeAgo(this.created_at)}</div>
        //                                         <div class="nme ${class_blv}">${this.name}</div>
        //                                     </div>
        //                                     ${this.stickerDV}
        //                                 </div>
        //                             </div>`;
        //                     setTimeout(() => {
        //                         $(`#${datart[0].id}`).append(this.str);
        //                         var hg = $('.chat-text').prop('scrollHeight');
        //                         $('.chat-text').animate({ scrollTop: hg+100 }, 5);
        //                     }, 500);
        //                 });
        //             },
        //         });
        //     }, 500);
        // })
        // function timeAgo(str_date) {
        //     var d = new Date();
        //     var UTCsecondsNow = (d.getTime() + d.getTimezoneOffset() * 60 * 1000);

        //     var date = new Date(str_date);
        //     var UTCseconds = (date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        //     var diff = UTCsecondsNow - UTCseconds;
        //     var tense = 'trước';
        //     if (diff < 0) {
        //         tense = 'sau';
        //         diff = Math.abs(diff);
        //     }
        //     if (diff === 0) return 0;
        //     var years = singular(Math.round(diff / 31557600000), 'năm');
        //     if (years)
        //     return years + tense;
        //     var months = singular(Math.round(diff / 2592000000), 'tháng');
        //     if (months)
        //     return months + tense;
        //     var days = singular(Math.round(diff / 86400000), 'ngày');
        //     if (days)
        //     return days + tense;
        //     var hours = singular(Math.round(diff / 3600000), 'giờ');
        //     if (hours)
        //     return hours + tense;
        //     var mins = singular(Math.round(diff / 60000), 'phút');
        //     if (mins)
        //     return mins + tense;
        //     var secs = singular(Math.round(diff / 1000), 'giây');
        //     if (secs){
        //         return secs + tense;
        //     }
        //     else return 'Vừa xong';
        // }
        // function singular(num, str) {
        //     if (num >= 1) {
        //         return num + ' ' + str + ' ';
        //     }
        //     return '';
        // }
    },
    beforeUnmount () {
        
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkKeo = response.data.data.list_data.keo
                this.linkCuoc = response.data.data.list_data.cuoc
                this.linkTele = response.data.data.list_data.telegram
                this.linkZalo = response.data.data.list_data.zalo
                this.linkLive = response.data.data.list_data.live
            })
        },
        changeTabMatch (tab) {
            this.tabSlMatch = tab
        },
        changeTab (tab) {
            this.tabSelected = tab
            this.tabs = tab
        },
        changeTab1 (tab) {
            this.tabSl = tab
            this.checkClickAdv = 0
            this.mutedTvc = 1
            setTimeout(() => {
                $('.nextvideo').show()
            }, 5000);
        },
        getData () {
            this.axios.get(this.api_listmatchdate).then((response) => {
                this.listItemdate1 = response.data.data
                this.tabSelected = Object.keys(this.listItemdate1)[0];
                this.tabs = Object.keys(this.listItemdate1)[0];
            })
        },
        getDataRoomLive () {
            this.axios.get(this.api_listmatchdate).then((response) => {
                let liveRoom = response.data.data
                let liveRoom1 = Object.values(liveRoom)[0];
                this.liveRoom = liveRoom1.slice(0, 4);


                let datart = this.liveRoom.slice(0,1)
                let dataImage = []
                datart.forEach(item => {
                    dataImage.push({...item});

                    let today = new Date();
                    let date = '';
                    if(today.getDate() < 10){
                        date = '0' + today.getDate();
                    }else{
                        date = today.getDate()
                    }
                    let month = today.getMonth()+1;
                    let date_month = date + '_' + month;

                    const form = new FormData();
                    form.append('day', date_month);
                    // 'https://keonhacai.1nguon.vip/b88/'+date_month+'/desktop/data.json' -> get
                    
                    setTimeout(() => {
                        this.axios.post(this.api_ratio, form).then((response) => {
                            
                            this.rt = response.data.oddData.live.event_map; // lấy trận
                            this.rt1 = response.data.oddData.live.odd_map; //lấy tỷ lệ
                        
                            const arr_match = Object.entries(this.rt).map((arr) => ({
                                ...arr[1]
                            }));

                            let arrmatch = []
                            arr_match.forEach(item => {
                                arrmatch.push({...item});
                            });
                            if(dataImage[0].match_auto==1 && (dataImage[0].match_id == 0 || dataImage[0].match_id == null)){
                                let val_1 = arrmatch.filter( product => {
                                    var nameHome = dataImage[0].name_home.split(' ')
                                    var nameAway = dataImage[0].name_away.split(' ')
                                    var checkHome = 0;
                                    var checkAway = 0;
                                    if(product.homeName.toLowerCase().includes(nameHome[0].toLowerCase()) && product.homeName.toLowerCase().includes(nameHome[1].toLowerCase())){
                                        checkHome = 1
                                    }
                                    if(product.awayName.toLowerCase().includes(nameAway[0].toLowerCase()) && product.awayName.toLowerCase().includes(nameAway[1].toLowerCase())){
                                        checkAway = 1
                                    }
                                    if( checkHome == 1 && checkAway == 1 ){
                                        return true
                                    }
                                    // var checkHome = 0;
                                    // var checkAway = 0;
                                    // $.each(nameHome, function() {
                                    //     if(this.toLowerCase() != 'fc' && this.toLowerCase() != 'club' && product.homeName.toLowerCase().includes(this.toLowerCase())){
                                    //         checkHome = 1
                                    //         return false;
                                    //     }
                                    // });
                                    // $.each(nameAway, function() {
                                    //     if(this.toLowerCase() != 'fc' && this.toLowerCase() != 'club' && product.awayName.toLowerCase().includes(this.toLowerCase())){
                                    //         checkAway = 1
                                    //         return false;
                                    //     }
                                    // });
                                    // if( checkHome == 1 && checkAway == 1 ){
                                    //     return true
                                    // }
                                });
                                this.rt3 = val_1.slice(0,1)

                                const array_ratio = Object.entries(this.rt1).map((arr) => ({
                                    ...arr
                                }));

                                if(val_1 != ''){
                                    this.rt2 = array_ratio.filter(d => d[0] === val_1[0].eventId)
                                }else{
                                    this.rt2 = 'null'
                                }
                                if(item.live > 0 && this.rt2 != 'null'){
                                    if(this.rt2[0][1][1]){
                                        this.rati1 = this.rt2[0][1][1]
                                    }
                                    if(this.rt2[0][1][3]){
                                        this.rati3 = this.rt2[0][1][3]
                                    }
                                    if(this.rt2[0][1][5]){
                                        this.rati5 = this.rt2[0][1][5]
                                    }
                                    if(this.rt2[0][1][7]){
                                        this.rati7 = this.rt2[0][1][7]
                                    }
                                    if(this.rt2[0][1][8]){
                                        this.rati8 = this.rt2[0][1][8]
                                    }
                                    if(this.rt2[0][1][15]){
                                        this.rati15 = this.rt2[0][1][15]
                                    }
                                }
                            }else{
                                let val_1 = arrmatch.filter( product => {
                                    return product.eventId == dataImage[0].match_id
                                });
                                this.rt3 = val_1.slice(0,1)

                                const array_ratio = Object.entries(this.rt1).map((arr) => ({
                                    ...arr
                                }));

                                if(val_1 != ''){
                                    this.rt2 = array_ratio.filter(d => d[0] === val_1[0].eventId)
                                }else{
                                    this.rt2 = 'null'
                                }
                                if(item.live > 0 && this.rt2 != 'null'){
                                    if(this.rt2[0][1][1]){
                                        this.rati1 = this.rt2[0][1][1]
                                    }
                                    if(this.rt2[0][1][3]){
                                        this.rati3 = this.rt2[0][1][3]
                                    }
                                    if(this.rt2[0][1][5]){
                                        this.rati5 = this.rt2[0][1][5]
                                    }
                                    if(this.rt2[0][1][7]){
                                        this.rati7 = this.rt2[0][1][7]
                                    }
                                    if(this.rt2[0][1][8]){
                                        this.rati8 = this.rt2[0][1][8]
                                    }
                                    if(this.rt2[0][1][15]){
                                        this.rati15 = this.rt2[0][1][15]
                                    }
                                }
                            }
                        })
                    }, 50);
                });
            })
        },
        getlocation () {
            this.axios.get('https://geolocation-db.com/json/').then((response) => {
                this.country = response.data.country_name
            })
        },
        viewCore(id, player) {
            this.players[id] = player;
            this.play(id)
        },
        viewCores(id, player) {
            this.players[id] = player;
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        playEnded() {
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        getDataPlayMatch () {
            this.axios.get(this.api_listmatchlive).then((response) => {
                this.listhot = response.data.data.filter(d => d.hot === 1)
                this.listPlayMatch = response.data.data.filter(d => d.live === 1)
            })
        },
        retrieveAllCustomer (url) {
            this.axios.get(url).then(res => {
                if(res.status===200){
                    this.check = 1
                }
            }).catch(e => {
                this.checkes = e
                this.check = 0
            })
            return this.check;
        },
        getDataRatio () {
            let today = new Date();
            let date = today.getDate();
            if(date < 10){
                date = '0' + date
            }
            let month = today.getMonth()+1;
            let date_month = date + '_' + month;
            
            // 'https://keonhacai.1nguon.vip/b88/'+date_month+'/desktop/data.json' -> get

            const form = new FormData();
            form.append('day', date_month);

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ 

                this.dataRatio2 = response.data.oddData.live.event_map; // lấy trận
                this.dataRatio3 = response.data.oddData.live.odd_map;
                
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                const arr_match1 = Object.entries(this.dataRatio2).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio1 = Object.entries(this.dataRatio3).map((arrs) => ({
                    ...arrs
                }));

                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio

                this.data_name_ratio2 = arr_match1
                this.data_name_ratio3 = arr_ratio1
            })
            // setTimeout(() => {
            // }, 50);
        },
        GetDateRatio (event) {
            this.datechange = event.target.value
            if (this.datechange != this.date1 + '_' + this.month1) {
                $('.item_ratio_home_live').hide()
            }else{
                $('.item_ratio_home_live').show()
            }

            const form = new FormData();
            form.append('day', this.datechange);

            // 'https://keonhacai.1nguon.vip/b88/'+this.datechange+'/desktop/data.json' -> get

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ
                
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio
            })
        },
        listIconChat (){
            this.axios.get(this.api_listicon).then((response) => {
                this.listIcon = response.data.data
            })
        },
        sendIconChat (event) {
            var dataImg = event.target.getAttribute('data_img')
            $('.dataImg_chat').val(dataImg)
            $('#chat-submit').trigger('click')
            $('.list_icon_chat').hide()
        },
        sendChat (e) {
            e.preventDefault();
            var thismd = this
            var url_lk = $('input[name="url-chat"]').val();
            var dataimage = $('.dataImg_chat').val()
            var data = {
                url: url_lk,
                name: this.name,
                content: this.content_chat,
                sticker: dataimage
            };
            $.ajax({
                method: "POST",
                url: 'https://f8betchat.com/api/chat/send-message',
                data: data,
                success : function(res) {
                    if(res.success== true){
                        thismd.content_chat = ''
                        $('.dataImg_chat').val('');
                    }
                },
                error: function (res) {
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: res.responseJSON.message,
                        icon: 'error',
                        timer: 2000
                    
                    });
                },
            });
            return false;
        },
        sendChats () {
            var url_lk = $('input[name="url-chat"]').val();
            var dataimage = $('.dataImg_chat').val()
            var data = {
                url: url_lk,
                name: this.name,
                content: this.content_chat,
                sticker: dataimage
            };
            $.ajax({
                method: "POST",
                url: 'https://f8betchat.com/api/chat/send-message',
                data: data,
                success : function(res) {
                    if(res.success== true){
                        $('.dataImg_chat').val('');
                    }
                },
                error: function (res) {
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: res.responseJSON.message,
                        icon: 'error',
                        timer: 2000
                    
                    });
                },
            });
            return false;
        },
        showIconChatLive () {
            $('.list_icon_chat').slideToggle();
        },
        convertDate (date) {
            var datecv = new Date(date);
            return datecv.toLocaleString()
        },
        onEditorReady(editor) {
            editor.editing.view.document.on('enter', (evt, data) => {
                if (data.isSoft) {
                    editor.execute('enter');
                } else {
                    this.sendChats()
                    editor.setData( '<p></p>' );
                    evt.stop();
                }
                    data.preventDefault();
                    evt.stop();
                    editor.editing.view.scrollToTheSelection();
            }, { priority: 'high' });
        },
        connect() {
            var thisAgo = this
            window.localStorage.setItem('namePath', 'Home_vaohang')
            var socket = SocketIO('https://f8betchat.com', {
                transports: ['websocket', 'polling', 'flashsocket'],
            });
            socket.on('chat:message_macth',function(result){
                var id_append = $('input[name="id-chat"]').val();
                var urlMatch = $('input[name="url-chat"]').val();
                // console.log('222222', window.localStorage.getItem('namePath'), id_append, urlMatch, result);
                if( result.message.action === 1 &&  urlMatch == result.message.url && window.localStorage.getItem('namePath') == 'Home_vaohang'){
                    let checkname = result.message.name.substr(0, 3).toLowerCase();
                    var class_blv = ''
                    if(checkname == 'blv'){
                        class_blv = 'active_color'
                    }else{
                        class_blv = ''
                    }

                    if(result.message.sticker !== null){
                        this.stickerDV = `<div class="imgsticker"><img src="https://f8betchat.com/${result.message.sticker}" alt=""></div>`;
                    }else{
                        this.stickerDV = `<div class="body content_chat_edit ${class_blv}">${result.message.content}</div>`;
                    }
                    // <span class="pic Empty"></span>
                    this.str = `<div class="flex_chat_icon msg msg-${result.message.id}">
                                <div class="img_random_chat">
                                    <img src="https://f8betchat.com/images/cic.gif" alt="">
                                </div>
                                <div class="content_text_chats">
                                    <div class="name_time_chat">
                                        <div class="dtxt" title="23 Nov 22, 02:10 AM" data-time="${result.message.created_at}">${thisAgo.timeAgo(result.message.created_at)}</div>
                                        <div class="nme ${class_blv}">${result.message.name}</div>
                                    </div>
                                    ${this.stickerDV}
                                </div>
                            </div>`;
                            
                    $(`#${id_append}`).append(this.str);
                    var hg = $('.chat-text').prop('scrollHeight');
                    $('.chat-text').animate({ scrollTop: hg+100 },500);
                }
                else{
                    $(`.msg-${result.message.id}`).remove();
                }
                this.data_time = $(`#${result.message.url}`).find('.dtxt');
                $.each(this.data_time, function(){
                    this.time = $(this).data('time');
                    $(this).html( thisAgo.timeAgo(this.time) );
                });
            });
        },
        timeAgo(str_date) {
            var d = new Date();
            var UTCsecondsNow = (d.getTime() + d.getTimezoneOffset() * 60 * 1000);

            var date = new Date(str_date);
            var UTCseconds = (date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            var diff = UTCsecondsNow - UTCseconds;
            var tense = 'trước';
            if (diff < 0) {
                tense = 'sau';
                diff = Math.abs(diff);
            }
            if (diff === 0) return 0;
            var years = this.singular(Math.round(diff / 31557600000), 'năm');
            if (years)
            return years + tense;
            var months = this.singular(Math.round(diff / 2592000000), 'tháng');
            if (months)
            return months + tense;
            var days = this.singular(Math.round(diff / 86400000), 'ngày');
            if (days)
            return days + tense;
            var hours = this.singular(Math.round(diff / 3600000), 'giờ');
            if (hours)
            return hours + tense;
            var mins = this.singular(Math.round(diff / 60000), 'phút');
            if (mins)
            return mins + tense;
            var secs = this.singular(Math.round(diff / 1000), 'giây');
            if (secs){
                return secs + tense;
            }
            else return 'Vừa xong';
        },
        singular(num, str) {
            if (num >= 1) {
                return num + ' ' + str + ' ';
            }
            return '';
        },
        getListblv () {
            this.axios.get(this.api_blv).then((response) => {
                this.listblv = response.data.data
            })
        },
        splitString (str) {
            let arr = str.split('"')
            return arr[1]
        },
        getlistApiMatch () {
            this.axios.get(this.api_matchCola).then((response) => {
                this.listMatchApi = this.shuffle(response.data.data.filter(d => d.result.match.matchStatus == 2))
                this.listMatchApiAll = response.data.data.slice(0,50)

                this.listNhaDai = response?.data?.data.filter(d => d.matchHot !== 0 && d.matchHot !== 1)
                this.ListHot = response?.data?.data.filter(d => d.matchHot == 1)
                this.ListMatchBlv = response?.data?.data.filter(d => d.matchHot == 0 || d.matchHot == 1)
                // console.log('111', this.ListMatchBlv);
                // let listLiveHome = response.data.data
                // const dataImage = [];
                // listLiveHome.forEach(item => {
                //     let check_mbut = 0
                //     this.axios.get(item.result.match.videoUrl).then(res => {
                //         if(res.status===200){
                //             check_mbut = 1
                //             dataImage.push({...item, check_mbut});
                //         }
                //     }).catch(e => {
                //         this.orther = e
                //         check_mbut = 0
                //         dataImage.push({...item, check_mbut});
                //     })
                // });
                // setTimeout(() => {
                //     this.listMatchApiAll = dataImage;
                //     console.log(this.listMatchApiAll.reverse());
                // }, 800);
            })
        },
        convertDatetime(d) {
            let gt7 = 7 * 3600
            let timeStamp_change = Number(d) + gt7
            return new Date(timeStamp_change * 1000).toISOString().slice(0, 19).replace('T', ' ')
        },
        replaceName (str) {
            return str.replaceAll('-', ' ')
        },
        convertDateStame (d) {
            let arr = d.split(' ')
            let ngaythang = arr[0]
            let arrNt = ngaythang.split('-')
            let giophut = arr[1]
            let arrGp = giophut.split(':')
            return arrNt[2] + '/' + arrNt[1] + ' ' + arrGp[0] + ':' + arrGp[1]
        },
        nextVideo () {
            this.checkClickAdv = 1
        },
        sendInfoModal (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_sendInfo, {
                name: this.fullname,
                phone: this.tell,
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.others = res
                this.showModal_euro = false
                this.showModal_euro1 = true
                this.fullname = this.tell = ''
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
    }
}
</script>

<style scoped>    
</style>
