<template>
    <div :class="classwp_header">
        <!-- <div class="banner_top_home">
            <a :href="linkCuoc" target="_blank">
                <img src="../assets/images/top.gif" alt="">
            </a>
        </div> -->
        <!-- <div class="banner_top_home_mb">
            <a :href="linkCuoc" target="_blank">
                <img src="../assets/images/bn_head_mb.gif" alt="">
            </a>
        </div> -->
        <header id="header" :class="classHeader" class="header has-sticky sticky-shrink">
            <div class="container maxwidth_container">
                <div class="menu_desktop">
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-12 left-logo">
                            <div id="logo" class="flex-col logo">
                                <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="LUAMACHTV trang chuyên live bóng đá, xem bóng đá trực tiếp full HD - F8BET" rel="home">
                                    <img src="../assets/images/logo.png" class="header_logo header-logo" alt="LUAMACHTV trang chuyên live bóng đá, xem bóng đá trực tiếp full HD">
                                </router-link>
                                <a v-else class="nav-item" href='/' title="LUAMACHTV trang chuyên live bóng đá, xem bóng đá trực tiếp full HD - F8BET" rel="home">
                                    <img src="../assets/images/logo.png" class="header_logo header-logo" alt="LUAMACHTV trang chuyên live bóng đá, xem bóng đá trực tiếp full HD">
                                </a>
                            </div>
                        </div>
                        <div class="col-md-9 col-sm-9 col-xs-12 right-menu">
                            <div class="wp-menu">
                                <ul class="header-nav">
                                    <li>
                                        <router-link class="nav-item" to='/' v-if="$route.path != '/'">Trang chủ</router-link>
                                        <a class="router-link-active nav-item" href='/' v-else>Trang chủ</a>
                                    </li>

                                    <li><router-link class="nav-item" to='/lich-thi-dau'>Lịch thi đấu</router-link></li>
                                    <li><router-link class="nav-item" to='/idol-live'>Idol Live</router-link></li>
                                    <li><router-link class="nav-item" to='/khuyen-mai'>Khuyến mãi</router-link></li>

                                    <li class="login_header" v-if="token == null"><a @click="showmdLgRg">Đăng nhập</a></li>
                                    <li class="register_header" v-if="token == null"><a @click="showModal = true">Đăng ký</a></li>
                                    <li class="fullname_login" v-if="token != null"><a href="javascript:;">{{ nameUser }}</a> <span @click="logout"><img src="../assets/images/icon/logout.png" alt=""></span></li>
                                </ul>
                                <img src="../assets/images/icon/sanglogin.png" alt="img bgr" v-if="token == null" class="img_abs_login">
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="'menu_mobile ' + active">
                    <p class="nav-menu-mobile" @click="clickMenu"><img src="../assets/images/menu.png" alt=""></p>
                    <p class="nav-menu-mobile close-mn" @click="clickRemove"><img src="../assets/images/close.png" alt=""></p>
                    <p @click="clickRemove" class="mgbt_mobile">
                        <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="LUAMACHTV trang chuyên live bóng đá, xem bóng đá trực tiếp full HD - F8BET" rel="home">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="LUAMACHTV trang chuyên live bóng đá, xem bóng đá trực tiếp full HD">
                        </router-link>
                        <a v-else class="nav-item" href='/' title="LUAMACHTV trang chuyên live bóng đá, xem bóng đá trực tiếp full HD - F8BET" rel="home">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="LUAMACHTV trang chuyên live bóng đá, xem bóng đá trực tiếp full HD">
                        </a>
                    </p>
                    <div class="menu-mb">
                        <ul>
                            <li @click="clickRemove">
                                <router-link class="nav-item" to='/' v-if="$route.path != '/'">Trang chủ</router-link>
                                <a class="nav-item router-link-active" href='/' v-else>Trang chủ</a>
                            </li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/lich-thi-dau'>Lịch thi đấu</router-link></li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/idol-live'>Idol live</router-link></li>
                            <li @click="clickRemove"><router-link class="nav-item" to='/khuyen-mai'>Khuyến mãi</router-link></li>
                            <li v-if="token == null"><a @click="clickRemovess">Đăng nhập</a></li>
                            <li v-if="token == null"><a @click="clickRemoves">Đăng ký</a></li>
                        </ul>
                    </div>
                    <div class="btnmobile_dndk">
                        <ul>
                            <li class="login_header" v-if="token == null"><a @click="clickRemovess">Đăng nhập</a></li>
                            <li class="register_header" v-if="token == null"><a @click="clickRemoves">Đăng ký</a></li>
                            <li class="fullname_login fullname_login_mobile" v-if="token != null"><a href="javascript:;">{{ nameUser }}</a> <span @click="logout"><img src="../assets/images/icon/logout.png" alt=""></span></li>
                        </ul>
                    </div>
                </div>
                <!-- modal register -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModal" @click="showModal = false"></div>
                    <div class="modal" v-if="showModal">
                        <div class="wp-modal-register">
                            <div class="header-modal">
                                <button class="close-modal_login" @click="closeModalLogin"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <div class="logo_modal_login">
                                        <div class="logo_mdal">
                                            <div class="img_md">
                                                <img src="../assets/images/logo.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_dangky">
                                        <form action="" method="post" id="frm_register" @submit="register" v-if="checkshow == 1">
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-xs-12 left_frm_register">
                                                    <div class="wrapper_left_register">
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/ht.png" alt=""> Họ tên</label>
                                                            <input type="text" v-model="name" placeholder="">
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/dt.png" alt=""> Số điện thoại</label>
                                                            <input type="text" v-model="phone" placeholder="">
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/us1.png" alt=""> Tên đăng nhập</label>
                                                            <input type="text" v-model="username" placeholder="">
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/pw1.png" alt=""> Mật khẩu thành viên</label>
                                                            <input type="password" v-model="password" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="btn_dangky">
                                                <button type="submit">Đăng ký ngay</button>
                                            </div>
                                            <p class="click_loadfrm_login">Bạn đã có tài khoản? <span @click="showLg">Đăng nhập</span></p>
                                        </form>
                                        <form action="" method="post" id="frm_login" @submit="login" v-else>
                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-xs-12 left_frm_register">
                                                    <div class="wrapper_left_register">
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/us1.png" alt=""> Tên đăng nhập</label>
                                                            <input type="text" v-model="username_lg" placeholder="">
                                                        </div>
                                                        <div class="input_dk">
                                                            <label><img src="../assets/images/pw1.png" alt=""> Mật khẩu</label>
                                                            <input type="password" v-model="password_lg" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="btn_dangky">
                                                <button type="submit">Đăng nhập</button>
                                            </div>
                                            <p class="click_loadfrm_login">Bạn chưa có tài khoản? <span @click="showRg">Đăng ký</span></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal register -->
            </div>
            <CurvedBottomNavigation :options="options" v-model="selected" background-color='#111219' foreground-color='#1F9AFF' />
        </header>
        <div id="loading_show" class="loading_show" style="position: fixed; left: 0; bottom: 0; top: 0%; right: 0%; text-align: center; background: rgba(0,0,0,.5); z-index: 999999999;">
			<div class="windows8"> 
				<div class="wBall" id="wBall_1"> 
					<div class="wInnerBall"></div>
				</div>
				<div class="wBall" id="wBall_2">
					<div class="wInnerBall"></div>
				</div>
				<div class="wBall" id="wBall_3">
					<div class="wInnerBall"></div>
				</div> 
				<div class="wBall" id="wBall_4">
					<div class="wInnerBall"></div> 
				</div>
				<div class="wBall" id="wBall_5"> 
					<div class="wInnerBall"></div>
				</div>
			</div>
		</div>
        <!-- <div class="banner_left">
            <a :href="linkCuoc" target="_blank">
                <img src="../assets/images/left.gif" alt="">
            </a>
        </div>
        <div class="banner_right">
            <a :href="linkCuoc" target="_blank">
                <img src="../assets/images/right.gif" alt="">
            </a>
        </div>
        <div class="banner_bottom">
            <div class="wp_bottom_bn">
                <a :href="linkCuoc" target="_blank">
                    <img src="../assets/images/bottom.gif" alt="">
                </a>
                <img src="../assets/images/cls.png" alt="" class="imgClose" @click="hideBanner">
            </div>
        </div> -->
        <!-- <div class="banner_left">
            <carousel :items-to-show="1" :wrap-around="true" :autoplay="6000">
                <slide :key="1">
                    <a :href="url_linkredirect" target="_blank">
                        <img src="../assets/images/slider/sl1.png" alt="">
                    </a>
                </slide>
                <slide :key="2">
                    <a :href="url_linkredirect" target="_blank">
                        <img src="../assets/images/slider/sl2.png" alt="">
                    </a>
                </slide>
                <slide :key="3">
                    <a :href="url_linkredirect" target="_blank">
                        <img src="../assets/images/slider/sl3.png" alt="">
                    </a>
                </slide>
            </carousel>
        </div>
        <div class="banner_right">
            <carousel :items-to-show="1" :wrap-around="true" :autoplay="6000">
                <slide :key="1">
                    <a :href="url_linkredirect" target="_blank">
                        <img src="../assets/images/slider/sl1.png" alt="">
                    </a>
                </slide>
                <slide :key="2">
                    <a :href="url_linkredirect" target="_blank">
                        <img src="../assets/images/slider/sl2.png" alt="">
                    </a>
                </slide>
                <slide :key="3">
                    <a :href="url_linkredirect" target="_blank">
                        <img src="../assets/images/slider/sl3.png" alt="">
                    </a>
                </slide>
            </carousel>
        </div> -->
        <div class="container maxwidth_container marquee_mbcss">
            <div class="textrun_header">
                <p>
                    <img src="../assets/images/icon/loa.png" alt="">
                    <span>Thông báo</span>
                </p>
                <div class="textRun_right">
                    <Vue3Marquee :pause-on-click="true" duration="100">
                        <div class="mqtext_mb">
                            <ul class="list_marquee">
                                <li><a href="javascript:;">🏆 Đã phát thưởng thành công quà ngẫu nhiên đến 1000 khách hàng may mắn vào mỗi thứ 7 và chủ nhật 🧧🧧</a></li>
                                <li><a href="javascript:;">🏆 Thưởng nạp đầu 100% 1 vòng cược lúc 13 giờ 00  đến 15 giờ 00 ❤️❤️</a></li>
                                <li><a href="javascript:;">🏆Các khuyến mãi lớn sẽ được phát đều đặn hàng tháng Luamachtv kính chúc Quý hội viên nhiều sức khỏe, hạnh phúc. Đặc biệt ngập tràn may mắn cược đâu thắng đó, cược to thắng lớn. ⭐️</a></li>
                            </ul>
                        </div>
                    </Vue3Marquee>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
// import { Carousel, Slide } from 'vue3-carousel'
export default {
    name: 'Header_Vaohang',
    data () {
        return {
            active: '',
            selected: 1,
            options: [
                { id: 2, icon: "falive_icon", title: "Kết quả", path: { name: "Kqbd_vh" } },
                { id: 3, icon: "fahome_ratio", title: "Tỷ lệ kèo", path: { name: "Ratio_vh" } },
                { id: 1, icon: "fahome_icon", title: "Trang chủ", path: { name: "Home_vaohang" } },
                { id: 4, icon: "falive18_icon", title: "Lịch thi đấu", path: { name: "Lichthidau_vh" } },
                { id: 5, icon: "fasupport", title: "BXH", path: { name: "Bxh_vh" } },
            ],
            classHeader: '',
            classwp_header: '',
            showModal: false,
            name: '',
            phone: '',
            username: '',
            password: '',
            username_lg: '',
            password_lg: '',
            checkshow: 1,
            codeCapchar: Math.floor(1000 + Math.random() * 9000),
            token: window.localStorage.getItem('token'),
            nameUser: window.localStorage.getItem('fullname'),
            other: []
        }
    },
    components: {
        // Carousel,
        // Slide,
    },
    created () {
        window.addEventListener('scroll', this.handleScroll)
        if(this.token != null){
            this.getListInfo()
        }
    },
    methods: {
        clickMenu () {
            this.active = 'active'
        },
        clickRemove () {
            this.active = ''
        },
        clickRemoves () {
            this.checkshow = 1
            this.active = ''
            this.showModal = true
        },
        clickRemovess () {
            this.checkshow = 2
            this.active = ''
            this.showModal = true
        },
        closeModalLogin () {
            this.showModal = false
            this.checkshow = 1
        },
        handleScroll () {
            if (window.scrollY > 150) {
                this.classHeader = 'active'
                this.classwp_header = 'root_headerEdit'
            }else{
                this.classHeader = ''
                this.classwp_header = ''
            }
        },
        register (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_register, {
                fullname: this.name,
                phone: this.phone,
                username: this.username,
                password: this.password
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.showModal = false
                this.name = this.username = this.password = this.phone = ''
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        login (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_login, {
                username: this.username_lg,
                password: this.password_lg
            }).then(res => {
                $('#loading_show').css('display', 'none')
                window.localStorage.setItem('token', res.data.data.token)
                window.localStorage.setItem('fullname', res.data.data.user.fullname)
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.showModal = false
                this.username_lg = this.password_lg = ''
                window.location.reload()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        logout () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_logout,
                {

                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                localStorage.clear();
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    window.location.href = '/'
                }, 2000);
            })
        },
        getListInfo () {
            this.axios.get(this.api_InfoUser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.other = response
            }).catch(e => {
                this.other = e
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 1000);
                }
            })
        },
        hideBanner () {
            $('.banner_bottom').hide()
        },
        showRg () {
            this.checkshow = 1
        },
        showLg () {
            this.checkshow = 2
        },
        showmdLgRg () {
            this.showModal = true
            this.checkshow = 2
        }
    }
}
</script>
<style>
</style>
