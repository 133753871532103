<template>
    <div class="root_room_live">
        <div class="bgr_room_live">
            <div class="container maxwidth_container root_live_mobile">
                <div class="wrapper_live_detail" :class="{ active: checkPlay == 2 }">
                    <div class="root_if_match">
                        <div class="live_room" v-for="(lives, indexs) in detailPlay" v-bind:key="indexs">
                            <div class="root_liveRoom">
                                <div class="video_live_room room_live_edit video_live_room_live_mb" v-if="checkPlay == 2">
                                    <div class="row">
                                        <div class="col-md-9 col-sm-9 col-xs-12 left_videohome">
                                            <div class="videohome_lf bgr_video">
                                                <div class="video_vietnam">
                                                    <div class="video_edit" v-if="retrieveAllCustomer(lives.result.match.videoUrl) == 1">
                                                        <vue3-video-player
                                                            autoplay
                                                            :core="HLSCore"
                                                            :src="lives.result.match.videoUrl"
                                                            title=""
                                                            resolution="408p"
                                                            :view-core="viewCore.bind(null, 'videolivecam')"
                                                            id="videolivecam"
                                                        />
                                                        <p class="viewmatch"><img src="../assets/images/eye.png" alt=""> {{ viewMatch }}</p>
                                                        <p class="blvmatch" v-if="activeTab == 4">{{ lives.blv }}</p>
                                                    </div>
                                                    <div class="video_edit" v-else>
                                                        <vue3-video-player
                                                            autoplay
                                                            :core="HLSCore"
                                                            :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                                            title=""
                                                            resolution="408p"
                                                            :view-core="viewCore.bind(null, 'videolivecamtvc')"
                                                            id="videolivecamtvc"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="info_matchlive">
                                                    <div class="row">
                                                        <div class="col-md-8 col-sm-8 col-xs-8 leftinfo_match">
                                                            <div class="leftmatch_info">
                                                                <div class="imgblv_lf">
                                                                    <img :src="url_linkimg + lives.image_blv" alt="" v-if="typeof lives?.blv !== 'undefined'">
                                                                    <img src="../assets/images/imgblv.png" alt="blv" v-else>
                                                                </div>
                                                                <div class="content_info">
                                                                    <p class="name_tysoinfo">
                                                                        <span class="span1">{{ replaceName(lives.result.match.homeTeam.slug).substring(0, 15) }}</span>
                                                                        <span class="span2" v-if="lives.result.match.matchStatus == 2 && typeof lives?.result?.match?.homeScores !== 'undefined'">{{ lives.result.match.homeScores[0] }} - {{ lives.result.match.awayScores[0] }}</span>
                                                                        <span class="span2" v-else>0 - 0</span>
                                                                        <span class="span3">{{ replaceName(lives.result.match.awayTeam.slug).substring(0, 15) }}</span>
                                                                    </p>
                                                                    <p class="blv_follow">
                                                                        <span class="span4" v-if="typeof lives?.blv !== 'undefined'">{{ lives.blv }}</span>
                                                                        <span class="span4" v-else>BLV Nhà Đài</span>
                                                                        <button><img src="../assets/images/icon/1.png" alt="icon"> Follow - <img src="../assets/images/icon/2.png" alt="follow"> {{ codeRandom }}</button>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-sm-4 col-xs-4 rightlogo_match">
                                                            <div class="rightmatch_logo">
                                                                <img src="../assets/images/icon/bg.png" alt="Nền" class="imgNen">
                                                                <img src="../assets/images/logo.png" alt="Logo" class="imglogo_if">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ghim_link pin_mobile">
                                            <p><a target="_blank" :href="linkTele">👉Vào nhóm nhận kèo từ BLV với hơn 3000 bet thủ: <span>{{ linkTele }}</span></a></p>
                                            <p><a target="_blank" :href="linkCuoc">👉CƯỢC NGAY: <span>{{ linkCuoc }}</span></a></p>
                                            <p><a target="_blank" :href="'https://zalo.me/'+ linkZalo">☎️ZALO : {{ linkZalo }}</a></p>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-12 right_chathome right_chathome_none" v-if="checkMobile == 1">
                                            <div class="chathome">
                                                <div class="wraper_chat">
                                                    <div class="ghim_link pin_desktop">
                                                        <ul>
                                                            <li>
                                                                <a :href="url_linkPromotion" target="_blank">
                                                                    <img src="../assets/images/btn/km.png" alt="" class="imgbtn_gim">
                                                                    <span>Khuyến mãi</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a :href="url_linkredirect" target="_blank">
                                                                    <img src="../assets/images/btn/dc.png" alt="" class="imgbtn_gim">
                                                                    <span>Đặt cược</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a :href="url_linkTele" target="_blank">
                                                                    <img src="../assets/images/btn/cskh.png" alt="" class="imgbtn_gim">
                                                                    <span>CSKH</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div class="contentGhim" v-html="ghimMess"></div>
                                                    </div>
                                                    <form method="post" action="" name="frm_chat" id="_frm_chat" @submit="sendChat">
                                                        <div class="chat-text" v-bind:id="idLv">
                                                        </div>
                                                        <div class="btn-chatbd" v-if="typeof lives?.blv !== 'undefined'">
                                                                <!-- <input type="text" v-model="name" placeholder="Tên hiển thị"> -->
                                                            <img src="../assets/images/icon.png" alt="" v-if="token != null" class="show_list_icon" @click="showIconChatLive">
                                                            <div class="list_icon_chat">
                                                                <div class="item_icon_chat">
                                                                    <p>
                                                                        <img v-for="(item, index) in listIcon" v-bind:key="index" :data_img="item.id" :src="domainIcon + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <!-- <input type="hidden" name="id-chat" class="id-chat" v-bind:value="idLv">
                                                            <input type="hidden" name="url-chat" class="url-chat" v-bind:value="urlLv"> -->
                                                            <input type="hidden" name="data-img" class="dataImg_chat">
                                                            <div class="wp-content">
                                                                <ckeditor :editor="editor" :config="editorConfig" v-on:ready="onEditorReady" id="content_chat" v-model="content_chat" class="content_desktop"></ckeditor>
                                                                <!-- <input type="text" class="content_mobile" id="content_chats" v-model="content_chat" placeholder="Nội dung"> -->
                                                            </div>
                                                            <button type="submit" class="btn-submit" id="chat-submit"></button>
                                                        </div>
                                                        <input type="hidden" name="id-chat" class="id-chat" v-bind:value="idLv">
                                                        <input type="hidden" name="url-chat" class="url-chat" v-bind:value="urlLv">
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="img_show_hide_chat">
                                                <p class="show_chatss"><img src="../assets/images/show_chat.png" alt=""></p>
                                                <p class="hide_chat"><img src="../assets/images/closec.png" alt=""></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="video_live_room room_live_edit video_live_room_live_mb" v-else>
                                    <div class="bgr_videos">
                                        <vue3-video-player
                                            autoplay
                                            :core="HLSCore"
                                            :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                            title=""
                                            resolution="408p"
                                            :view-core="viewCore.bind(null, 'videolivecamtvc')"
                                            id="videolivecamtvc"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container content-margin maxwidth_container root_live_mobile">
            <div class="wrapper_live_detail" :class="{ active: checkPlay == 2 }">
                <div class="root_if_match">
                    <div class="live_room" v-for="(lives, indexs) in detailPlay" v-bind:key="indexs">

                        <!-- content desktop -->
                            <div class="content_desktop_show" v-if="checkMobile == 1">
                                <div class="tab_desktop_liveshow">
                                    <ul>
                                        <li :class="{active: tabSelected2 == 'tab1'}" v-on:click="changeTab2('tab1')"><img src="../assets/images/ddr.png" alt=""> Trận đấu</li>
                                        <li :class="{active: tabSelected2 == 'tab2'}" v-on:click="changeTab2('tab2')"><img src="../assets/images/tlk.png" alt=""> Tỷ lệ kèo</li>
                                        <li :class="{active: tabSelected2 == 'tab3'}" v-on:click="changeTab2('tab3')"><img src="../assets/images/bxh.png" alt=""> Bảng xếp hạng</li>
                                    </ul>
                                </div>
                                <div class="content_tab_mobileshow">
                                    <div class="root_content_tab_mbshow root_content_tab_mbshows" v-if="tabSelected2 === 'tab1'">
                                        <div class="match_trochuyen_mobile">
                                            <div class="tab_match_home">
                                                <ul>
                                                    <li :class="{active: tabSlMatch == 'tab1'}" v-on:click="changeTabMatch('tab1')"><img src="../assets/images/icon/bongda.png" alt=""> Bóng đá <img src="../assets/images/live.gif" alt="" class="imglive_tab"><span>{{ listMatchApiAll.length }}</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab2'}" v-on:click="changeTabMatch('tab2')"><img src="../assets/images/icon/bongro.png" alt=""> Bóng rổ <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab3'}" v-on:click="changeTabMatch('tab3')"><img src="../assets/images/icon/esport.png" alt=""> E sport <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab4'}" v-on:click="changeTabMatch('tab4')"><img src="../assets/images/icon/bida.png" alt=""> Bida <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab5'}" v-on:click="changeTabMatch('tab5')"><img src="../assets/images/icon/casino.png" alt=""> Casino <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab6'}" v-on:click="changeTabMatch('tab6')"><img src="../assets/images/icon/duaxe.png" alt=""> Đua xe <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab7'}" v-on:click="changeTabMatch('tab7')"><img src="../assets/images/icon/vothuat.png" alt=""> Võ thuật <span>0</span></li>
                                                </ul>
                                            </div>
                                            <div class="wrapper_match_tabhome">
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab1'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <div class="row">
                                                                <div class="col-md-3 col-sm-3 col-xs-4 item-list_edit" :class="{ active: idLv == item.result.match.id }" v-for="(item, index) in listMatchApiAll" v-bind:key="index">
                                                                    <div class="item-list">
                                                                        <router-link class="nav-item" v-bind:to="{ name: 'Live_vh', params: { id: item.result.match.id, check: item.result.match.matchStatus, tab: 1, url: item.result.match.homeTeam.slug + '-vs-' + item.result.match.awayTeam.slug + '-' +  item.result.match.id } }">
                                                                            <div class="content_match_edit">
                                                                                <div class="giaidau_ngaydau">
                                                                                    <div class="date-match">
                                                                                        <p>
                                                                                            <span class="nonemb" v-if="replaceName(item.result.match.competition.slug).length < 10">{{ replaceName(item.result.match.competition.slug) }}</span>
                                                                                            <span class="nonemb" v-else>{{ replaceName(item.result.match.competition.slug).substring(0, 10) }}</span>
                                                                                            <span class="img_livemacth"><img src="../assets/images/live_1.gif" alt="" v-if="item.result.match.matchStatus == 2"></span>
                                                                                            <span class="date-lt">{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="center-list">
                                                                                    <div class="name_logo_doi">
                                                                                        <img :src="item.result.match.homeTeam.logo" class="img-logolt" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                                                        <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" class="img-logolt" alt="" v-else>
                                                                                        <span class="name-lt">{{ replaceName(item.result.match.homeTeam.slug) }}</span>
                                                                                    </div>
                                                                                    <div class="tyso_trandau">
                                                                                        <span class="nenxanh" v-if="item.result.match.matchStatus == 2 && typeof item?.result?.match?.homeScores !== 'undefined'">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</span>
                                                                                        <span class="khongnen" v-else>VS</span>
                                                                                    </div>
                                                                                    <div class="name_logo_doi">
                                                                                        <img :src="item.result.match.awayTeam.logo" class="img-logolt" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                                                        <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" class="img-logolt" alt="" v-else>
                                                                                        <span class="name-lt">{{ replaceName(item.result.match.awayTeam.slug) }}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="nameblv_match">
                                                                                    <span class="blv-class" v-if="typeof item?.blv !== 'undefined'"><img :src="url_linkimg + item.image_blv" alt=""> {{ item.blv }}</span>
                                                                                    <span class="blv-class" v-else><img src="../assets/images/imgblv.png" alt=""> BLV Nhà Đài</span>
                                                                                    <span class="sapdienra" v-if="item.result.match.matchStatus == 2">Đang diễn ra</span>
                                                                                    <span class="sapdienra" v-else>Sắp diễn ra</span>
                                                                                </div>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab2'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab3'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab4'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab5'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab6'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab7'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="root_content_tab_mbshow root_content_tab_mbshows" v-if="tabSelected2 === 'tab2'">
                                        <div class="ratio_trochuyen_mobile">
                                            <div class="co_tyso_match co_tyso_match_dt">
                                                <div class="co_match">
                                                    <img :src="lives.result.match.homeTeam.logo" alt="" v-if="typeof lives?.blv !== 'undefined'">
                                                    <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + lives.result.match.homeTeam.logo" alt="" v-else>
                                                </div>
                                                <div class="tyso_match">
                                                    <p class="tyso_mobile" v-if="typeof lives?.result?.match?.homeScores !== 'undefined'">{{ lives.result.match.homeScores[0] }} - {{ lives.result.match.awayScores[0] }}</p>
                                                    <p class="live_tyso_match">Live</p>
                                                </div>
                                                <div class="co_match">
                                                    <img :src="lives.result.match.awayTeam.logo" alt="" v-if="typeof lives?.blv !== 'undefined'">
                                                    <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + lives.result.match.awayTeam.logo" alt="" v-else>
                                                </div>
                                            </div>
                                            <div class="ratio_desktop_tab">
                                                <div class="title_tylekeo_desktop">
                                                    <div class="rto_1">
                                                        <div class="tt_1">
                                                            <div class="text_tt1">Cả trận</div>
                                                        </div>
                                                        <div class="item_tt1">
                                                            <div class="it_1">Tỷ lệ</div>
                                                            <div class="it_2">Tài xỉu</div>
                                                            <div class="it_3">1x2</div>
                                                        </div>
                                                    </div>
                                                    <div class="rto_1 rto_2">
                                                        <div class="tt_1 tt_2">
                                                            <div class="text_tt1 text_tt2">Hiệp 1</div>
                                                        </div>
                                                        <div class="item_tt1 item_tt2">
                                                            <div class="it_1">Tỷ lệ</div>
                                                            <div class="it_2">Tài xỉu</div>
                                                            <div class="it_3">1x2</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content_rto_dt">
                                                    <div class="it_rto_ct bdrdu_lf">
                                                        <div class="lf_rto_ct" v-if="typeof ratioMatchDetail[0]?.handicap != 'undefined'">
                                                            <p>{{ convertRatio(splitString(ratioMatchDetail[0].handicap)[2])}}</p>
                                                        </div>
                                                        <div class="rg_rto_ct">
                                                            <div v-if="typeof ratioMatchDetail[0]?.handicap != 'undefined'">
                                                                <p>{{ splitString(ratioMatchDetail[0].handicap)[3] }}</p>
                                                                <p>{{ splitString(ratioMatchDetail[0].handicap)[4] }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="it_rto_ct">
                                                        <div class="lf_rto_ct" v-if="typeof ratioMatchDetail[0]?.overUnder != 'undefined'">
                                                            <p>{{ convertRatio(splitString(ratioMatchDetail[0].overUnder)[2]) }}</p>
                                                        </div>
                                                        <div class="rg_rto_ct">
                                                            <div v-if="typeof ratioMatchDetail[0]?.overUnder != 'undefined'">
                                                                <p>{{ splitString(ratioMatchDetail[0].overUnder)[3] }}</p>
                                                                <p>{{ splitString(ratioMatchDetail[0].overUnder)[4] }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="it_rto_ct">
                                                        <div class="one_rto_ct" v-if="typeof ratioMatchDetail[0]?.europeOdds != 'undefined'">
                                                            <p>{{ splitString(ratioMatchDetail[0].europeOdds)[2] }}</p>
                                                            <p>{{ splitString(ratioMatchDetail[0].europeOdds)[3] }}</p>
                                                            <p>{{ splitString(ratioMatchDetail[0].europeOdds)[4] }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="it_rto_ct">
                                                        <div class="lf_rto_ct" v-if="typeof ratioMatchDetail[0]?.handicapHalf != 'undefined'">
                                                            <p>{{ convertRatio(splitString(ratioMatchDetail[0].handicapHalf)[2])}}</p>
                                                        </div>
                                                        <div class="rg_rto_ct">
                                                            <div v-if="typeof ratioMatchDetail[0]?.handicapHalf != 'undefined'">
                                                                <p>{{ splitString(ratioMatchDetail[0].handicapHalf)[3] }}</p>
                                                                <p>{{ splitString(ratioMatchDetail[0].handicapHalf)[4] }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="it_rto_ct">
                                                        <div class="lf_rto_ct" v-if="typeof ratioMatchDetail[0]?.overUnderHalf != 'undefined'">
                                                            <p>{{ convertRatio(splitString(ratioMatchDetail[0].overUnderHalf)[2])}}</p>
                                                        </div>
                                                        <div class="rg_rto_ct">
                                                            <div v-if="typeof ratioMatchDetail[0]?.overUnderHalf != 'undefined'">
                                                                <p>{{ splitString(ratioMatchDetail[0].overUnderHalf)[3] }}</p>
                                                                <p>{{ splitString(ratioMatchDetail[0].overUnderHalf)[4] }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="it_rto_ct bdrdu_rg">
                                                        <div class="one_rto_ct">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="root_content_tab_mbshow root_content_tab_mbshows" v-if="tabSelected2 === 'tab3'">
                                        <div class="bxh_trochuyen_mobile">
                                            <DetailBxh_vh />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- end content desktop -->

                        <!-- content mobile -->
                            <div class="content_mobile_show" v-if="checkMobile == 2">
                                <div class="tab_mobile_liveshow">
                                    <ul>
                                        <li :class="{active: tabSelected1 == 'tab1'}" v-on:click="changeTab1('tab1')">Trận đấu</li>
                                        <li :class="{active: tabSelected1 == 'tab2'}" v-on:click="changeTab1('tab2')">Trò chuyện</li>
                                        <li :class="{active: tabSelected1 == 'tab3'}" v-on:click="changeTab1('tab3')">Tỷ lệ kèo</li>
                                        <li :class="{active: tabSelected1 == 'tab4'}" v-on:click="changeTab1('tab4')">BXH</li>
                                    </ul>
                                </div>
                                <div class="content_tab_mobileshow">
                                    <div class="root_content_tab_mbshow root_content_tab_mbshows" v-if="tabSelected1 === 'tab1'">
                                        <div class="match_trochuyen_mobile">
                                            <div class="tab_match_home">
                                                <ul>
                                                    <li :class="{active: tabSlMatch == 'tab1'}" v-on:click="changeTabMatch('tab1')"><img src="../assets/images/icon/bongda.png" alt=""> Bóng đá <img src="../assets/images/live.gif" alt="" class="imglive_tab"><span>{{ listMatchApiAll.length }}</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab2'}" v-on:click="changeTabMatch('tab2')"><img src="../assets/images/icon/bongro.png" alt=""> Bóng rổ <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab3'}" v-on:click="changeTabMatch('tab3')"><img src="../assets/images/icon/esport.png" alt=""> E sport <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab4'}" v-on:click="changeTabMatch('tab4')"><img src="../assets/images/icon/bida.png" alt=""> Bida <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab5'}" v-on:click="changeTabMatch('tab5')"><img src="../assets/images/icon/casino.png" alt=""> Casino <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab6'}" v-on:click="changeTabMatch('tab6')"><img src="../assets/images/icon/duaxe.png" alt=""> Đua xe <span>0</span></li>
                                                    <li :class="{active: tabSlMatch == 'tab7'}" v-on:click="changeTabMatch('tab7')"><img src="../assets/images/icon/vothuat.png" alt=""> Võ thuật <span>0</span></li>
                                                </ul>
                                            </div>
                                            <div class="wrapper_match_tabhome">
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab1'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <div class="row">
                                                                <div class="col-md-3 col-sm-3 col-xs-4 item-list_edit" :class="{ active: idLv == item.result.match.id }" v-for="(item, index) in listMatchApiAll" v-bind:key="index">
                                                                    <div class="item-list">
                                                                        <router-link class="nav-item" v-bind:to="{ name: 'Live_vh', params: { id: item.result.match.id, check: item.result.match.matchStatus, tab: 1, url: item.result.match.homeTeam.slug + '-vs-' + item.result.match.awayTeam.slug + '-' +  item.result.match.id } }">
                                                                            <div class="content_match_edit">
                                                                                <div class="giaidau_ngaydau">
                                                                                    <div class="date-match">
                                                                                        <p>
                                                                                            <span class="nonemb" v-if="replaceName(item.result.match.competition.slug).length < 10">{{ replaceName(item.result.match.competition.slug) }}</span>
                                                                                            <span class="nonemb" v-else>{{ replaceName(item.result.match.competition.slug).substring(0, 10) }}</span>
                                                                                            <span class="img_livemacth"><img src="../assets/images/live_1.gif" alt="" v-if="item.result.match.matchStatus == 2"></span>
                                                                                            <span class="date-lt">{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="center-list">
                                                                                    <div class="name_logo_doi">
                                                                                        <img :src="item.result.match.homeTeam.logo" class="img-logolt" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                                                        <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" class="img-logolt" alt="" v-else>
                                                                                        <span class="name-lt">{{ replaceName(item.result.match.homeTeam.slug) }}</span>
                                                                                    </div>
                                                                                    <div class="tyso_trandau">
                                                                                        <span class="nenxanh" v-if="item.result.match.matchStatus == 2 && typeof item?.result?.match?.homeScores !== 'undefined'">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</span>
                                                                                        <span class="khongnen" v-else>VS</span>
                                                                                    </div>
                                                                                    <div class="name_logo_doi">
                                                                                        <img :src="item.result.match.awayTeam.logo" class="img-logolt" alt="" v-if="typeof item?.blv !== 'undefined'">
                                                                                        <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" class="img-logolt" alt="" v-else>
                                                                                        <span class="name-lt">{{ replaceName(item.result.match.awayTeam.slug) }}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="nameblv_match">
                                                                                    <span class="blv-class" v-if="typeof item?.blv !== 'undefined'"><img :src="url_linkimg + item.image_blv" alt=""> {{ item.blv }}</span>
                                                                                    <span class="blv-class" v-else><img src="../assets/images/imgblv.png" alt=""> BLV Nhà Đài</span>
                                                                                    <span class="sapdienra" v-if="item.result.match.matchStatus == 2">Đang diễn ra</span>
                                                                                    <span class="sapdienra" v-else>Sắp diễn ra</span>
                                                                                </div>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab2'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab3'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab4'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="root_itemMatchHome" v-if="tabSlMatch === 'tab5'">
                                                    <div class="tab-content">
                                                        <div class="allmatch">
                                                            <h2 class="title_update_match">Đang cập nhật</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="root_content_tab_mbshow root_content_tab_mbshows" v-if="tabSelected1 === 'tab2'">
                                        <div class="form_trochuyen_mobile">
                                            <form method="post" action="" name="frm_chat" id="_frm_chat" @submit="sendChat">
                                                <div class="chat-text" v-bind:id="idLv"></div>
                                                <div class="btn-chatbd" v-if="typeof lives?.blv !== 'undefined'">
                                                    <!-- <div class="name_chat"> -->
                                                        <!-- <input type="text" v-model="name" placeholder="Tên hiển thị"> -->
                                                        <img src="../assets/images/icon.png" v-if="token != null" alt="" class="show_list_icon" @click="showIconChatLive">
                                                        <div class="list_icon_chat">
                                                            <div class="item_icon_chat">
                                                                <p>
                                                                    <img v-for="(item, index) in listIcon" v-bind:key="index" :data_img="item.id" :src="domainIcon + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                                                </p>
                                                            </div>
                                                        </div>
                                                    <!-- </div> -->
                                                    <!-- <input type="hidden" name="id-chat" class="id-chat" v-bind:value="idLv">
                                                    <input type="hidden" name="url-chat" class="url-chat" v-bind:value="urlLv"> -->
                                                    <input type="hidden" name="data-img" class="dataImg_chat">
                                                    <div class="wp-content">
                                                        <ckeditor :editor="editor" :config="editorConfig" v-on:ready="onEditorReady" id="content_chat" v-model="content_chat" class="content_desktop"></ckeditor>
                                                    </div>
                                                    <button type="submit" class="btn-submit" id="chat-submit"></button>
                                                </div>
                                                <input type="hidden" name="id-chat" class="id-chat" v-bind:value="idLv">
                                                <input type="hidden" name="url-chat" class="url-chat" v-bind:value="urlLv">
                                            </form>
                                        </div>
                                    </div>
                                    <div class="root_content_tab_mbshow" v-if="tabSelected1 === 'tab3'">
                                        <div class="ratio_trochuyen_mobile">
                                            <div class="co_tyso_match">
                                                <div class="co_match">
                                                    <img :src="lives.result.match.homeTeam.logo" alt="" v-if="typeof lives?.blv !== 'undefined'">
                                                    <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + lives.result.match.homeTeam.logo" alt="" v-else>
                                                </div>
                                                <div class="tyso_match">
                                                    <p class="tyso_mobile" v-if="typeof lives?.result?.match?.homeScores !== 'undefined'">{{ lives.result.match.homeScores[0] }} - {{ lives.result.match.awayScores[0] }}</p>
                                                    <p class="live_tyso_match">Live</p>
                                                </div>
                                                <div class="co_match">
                                                    <img :src="lives.result.match.awayTeam.logo" alt="" v-if="typeof lives?.blv !== 'undefined'">
                                                    <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + lives.result.match.awayTeam.logo" alt="" v-else>
                                                </div>
                                            </div>
                                            <div class="ratio_mobile_tab">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Tỷ lệ</th>
                                                            <th>Tài xỉu</th>
                                                            <th>1x2</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="td_tyle">
                                                                <span v-if="typeof ratioMatchDetail[0]?.handicap != 'undefined'">{{ convertRatio(splitString(ratioMatchDetail[0].handicap)[2]) }}</span>
                                                                <span v-if="typeof ratioMatchDetail[0]?.handicap != 'undefined'">{{ splitString(ratioMatchDetail[0].handicap)[3] }} <br /> {{ splitString(ratioMatchDetail[0].handicap)[4] }}</span>
                                                            </td>
                                                            <td class="td_tyle">
                                                                <span v-if="typeof ratioMatchDetail[0]?.overUnder != 'undefined'">{{ convertRatio(splitString(ratioMatchDetail[0].overUnder)[2]) }}</span>
                                                                <span v-if="typeof ratioMatchDetail[0]?.overUnder != 'undefined'">{{ splitString(ratioMatchDetail[0].overUnder)[3] }} <br /> {{ splitString(ratioMatchDetail[0].overUnder)[4] }}</span>
                                                            </td>
                                                            <td class="td1x2">
                                                                <span v-if="typeof ratioMatchDetail[0]?.europeOdds != 'undefined'">{{ splitString(ratioMatchDetail[0].europeOdds)[2] }}</span>
                                                                <span v-if="typeof ratioMatchDetail[0]?.europeOdds != 'undefined'">{{ splitString(ratioMatchDetail[0].europeOdds)[3] }}</span>
                                                                <span v-if="typeof ratioMatchDetail[0]?.europeOdds != 'undefined'">{{ splitString(ratioMatchDetail[0].europeOdds)[4] }}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="root_content_tab_mbshow root_content_tab_mbshows" v-if="tabSelected1 === 'tab4'">
                                        <div class="bxh_trochuyen_mobile">
                                            <DetailBxh_vh />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- end content mobile -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailBxh_vh from '@/components/DetailBxh'
import HLSCore from '@cloudgeek/playcore-hls'
// import Swal from 'sweetalert2'
import {reactive} from "vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue';

import 'vue3-carousel/dist/carousel.css'
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on('click', '.hide_chat', function() {
        $('.chathome').addClass('hide_chat_click');
        $(this).hide()
        $('.show_chatss').show()
    });
    $(document).on('click', '.show_chatss', function() {
        $('.chathome').removeClass('hide_chat_click');
        $(this).hide()
        $('.hide_chat').show()
    });
    
    $(document).on("click",".tab_bxh ul li",function() {
        $('.tab_bxh ul li').removeClass('active');
        $(this).addClass('active');
    });
    $(document).on("click",".date_ratio_home label",function() {
        $('.date_ratio_home label').parent().removeClass('active');
        $(this).parent().addClass('active');
    });
});
export default {
    name: 'Live_vh',
    setup() {
        const data = reactive({
            description: '',
        })
        return {
            data,
            editor: ClassicEditor,
            breakpoints: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "center"
				},
				575: {
					itemsToShow: 2,
					snapAlign: "center"
				},
				768: {
					itemsToShow: 3,
					snapAlign: "center"
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "center"
				},
                1280: {
					itemsToShow: 4,
					snapAlign: "start"
				},
			}
        }
    },
    data () {
        return {
            tabSelected: '',
            tabSelected1: 'tab1',
            tabSelected2: 'tab1',
            listItemdate1: [],
            tabs: '',
            liveRoom: [],
            country: 'Vietnam',
            players: {},
            volume: 90,
            HLSCore,
            hlight: [],
            listPlayMatch: [],
            check: '',
            checke: '',
            dataRatio: [],
            dataRatio1: [],
            dataRatio2: [],
            dataRatio3: [],
            data_name_ratio: [],
            data_name_ratio1: [],
            data_name_ratio2: [],
            data_name_ratio3: [],
            date1: '',
            date2: '',
            date3: '',
            date4: '',
            month1: '',
            month2: '',
            month3: '',
            month4: '',
            datechange: '',
            listhot: [],
            rt: [],
            rt1: [],
            rt2: [],
            rt3: [],
            rati1: [],
            rati3: [],
            rati5: [],
            rati7: [],
            rati8: [],
            rati15: [],
            listIcon: [],
            name: '',
            content_chat: '',
            stickerDV: '',
            idLv: this.$route.params.id,
            urlLv: this.$route.params.url,
            checkPlay: this.$route.params.check,
            activeTab: this.$route.params.tab,
            listF8: [],
            listF8s: [],
            editorConfig: {
                toolbar: [ 'italic', 'bold'],
                placeholder: "Nội dung",
            },
            checkMobile: 1,
            linkKeo: '',
            linkCuoc: '',
            linkTele: '',
            linkZalo: '',
            linkLive: '',
            listMatchApi: [],
            listMatchApiAll: [],
            detailPlay: [],
            checkClickAdv: 0,

            
            tabSlMatch: 'tab1',
            listNhaDai: [],
            ListHot: [],
            ListMatchBlv: [],
            viewMatch: '',
            checkTime: 0,
            other: [],
            ratioMatchDetail: [],
            codeRandom: Math.floor(1000 + Math.random() * 9000),
            token: window.localStorage.getItem('token'),
            ghimMess: ''
        }
    },
    components: {
        ckeditor: CKEditor.component,
        DetailBxh_vh,
    },
    created () {
        if(this.activeTab == 1){
            this.tabSlMatch = 'tab1'
        }else if(this.activeTab == 2){
            this.tabSlMatch = 'tab2'
        }else if(this.activeTab == 3){
            this.tabSlMatch = 'tab3'
        }else if(this.activeTab == 4){
            this.tabSlMatch = 'tab4'
        }else{
            this.tabSlMatch = 'tab5'
        }
        this.getData()
        this.getDataRoomLive()
        this.getlocation()
        this.getDataPlayMatch ()
        this.listIconChat()
        setInterval(this.getDataRoomLive, 60000);
        this.getInfoLink()
        this.getlistApiMatch()
        setTimeout(() => {
            $('.nextvideo').show()
        }, 5000);
        this.getViewMatch()
        setInterval(this.getViewMatch, 60000);
        setInterval(this.checkTeenMinute, 10000);
        this.getDataListChat()
        this.getContentGhim()
    },
    mounted () {
        const isMobile = /Mobi/i.test(navigator.userAgent)
        if(isMobile){
            // mobile
            this.checkMobile = 2
        }else{
            // desktop
            this.checkMobile = 1
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const today = new Date();
        const tomorrow1 = new Date();
        const tomorrow2 = new Date();
        const tomorrow3 = new Date();
        const tomorrow4 = new Date();
        
        var d1 = new Date(tomorrow1.setDate(today.getDate())).toLocaleDateString("en-US")
        var d2 = new Date(tomorrow2.setDate(today.getDate() + 1)).toLocaleDateString("en-US")
        var d3 = new Date(tomorrow3.setDate(today.getDate() + 2)).toLocaleDateString("en-US")
        var d4 = new Date(tomorrow4.setDate(today.getDate() + 3)).toLocaleDateString("en-US")

        this.date1 = d1.split('/')[1]
        if(this.date1 < 10){
            this.date1 = '0' + this.date1
        }
        this.date2 = d2.split('/')[1]
        if(this.date2 < 10){
            this.date2 = '0' + this.date2
        }
        this.date3 = d3.split('/')[1]
        if(this.date3 < 10){
            this.date3 = '0' + this.date3
        }
        this.date4 = d4.split('/')[1]
        if(this.date4 < 10){
            this.date4 = '0' + this.date4
        }

        this.month1 = d1.split('/')[0]
        this.month2 = d2.split('/')[0]
        this.month3 = d3.split('/')[0]
        this.month4 = d4.split('/')[0]
    },
    beforeUnmount () {
        
    },
    methods: {
        changeTabMatch (tab) {
            this.tabSlMatch = tab
        },
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkKeo = response.data.data.list_data.keo
                this.linkCuoc = response.data.data.list_data.cuoc
                this.linkTele = response.data.data.list_data.telegram
                this.linkZalo = response.data.data.list_data.zalo
                this.linkLive = response.data.data.list_data.live
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
            this.tabs = tab
        },
        changeTab1 (tab) {
            this.tabSelected1 = tab
            if(this.tabSelected1 == 'tab1'){
                this.getDataListChat()
            }
        },
        changeTab2 (tab) {
            this.tabSelected2 = tab
        },
        getData () {
            this.axios.get(this.api_listmatchdate).then((response) => {
                this.listItemdate1 = response.data.data
                this.tabSelected = Object.keys(this.listItemdate1)[0];
                this.tabs = Object.keys(this.listItemdate1)[0];
            })
        },
        getDataRoomLive () {
            this.axios.get(this.api_listmatchlive).then((response) => {
                this.liveRoom = response.data.data.filter(d => d.id === this.idLv)
                let dataImage = []
                this.liveRoom.forEach(item => {
                    dataImage.push({...item});

                    let today = new Date();
                    let date = '';
                    if(today.getDate() < 10){
                        date = '0' + today.getDate();
                    }else{
                        date = today.getDate()
                    }
                    let month = today.getMonth()+1;
                    let date_month = date + '_' + month;

                    const form = new FormData();
                    form.append('day', date_month);
                    
                    setTimeout(() => {
                        this.axios.post(this.api_ratio, form).then((response) => {
                            
                            this.rt = response.data.oddData.live.event_map; // lấy trận
                            this.rt1 = response.data.oddData.live.odd_map; //lấy tỷ lệ
                        
                            const arr_match = Object.entries(this.rt).map((arr) => ({
                                ...arr[1]
                            }));

                            let arrmatch = []
                            arr_match.forEach(item => {
                                arrmatch.push({...item});
                            });

                            if(dataImage[0].match_auto==1 && (dataImage[0].match_id == 0 || dataImage[0].match_id == null)){
                                let val_1 = arrmatch.filter( product => {
                                    var nameHome = dataImage[0].name_home.split(' ')
                                    var nameAway = dataImage[0].name_away.split(' ')
                                    var checkHome = 0;
                                    var checkAway = 0;
                                    if(product.homeName.toLowerCase().includes(nameHome[0].toLowerCase()) && product.homeName.toLowerCase().includes(nameHome[1].toLowerCase())){
                                        checkHome = 1
                                    }
                                    if(product.awayName.toLowerCase().includes(nameAway[0].toLowerCase()) && product.awayName.toLowerCase().includes(nameAway[1].toLowerCase())){
                                        checkAway = 1
                                    }
                                    if( checkHome == 1 && checkAway == 1 ){
                                        return true
                                    }
                                    // var checkHome = 0;
                                    // var checkAway = 0;
                                    // $.each(nameHome, function() {
                                    //     if(this.toLowerCase() != 'fc' && this.toLowerCase() != 'club' && product.homeName.toLowerCase().includes(this.toLowerCase())){
                                    //         checkHome = 1
                                    //     }
                                    // });
                                    // $.each(nameAway, function() {
                                    //     if(this.toLowerCase() != 'fc' && this.toLowerCase() != 'club' && product.awayName.toLowerCase().includes(this.toLowerCase())){
                                    //         checkAway = 1
                                    //     }
                                    // });
                                    // if( checkHome == 1 && checkAway == 1 ){
                                    //     return true
                                    // }
                                });

                                this.rt3 = val_1.slice(0,1)

                                const array_ratio = Object.entries(this.rt1).map((arr) => ({
                                    ...arr
                                }));

                                if(val_1 != ''){
                                    this.rt2 = array_ratio.filter(d => d[0] === val_1[0].eventId)
                                }else{
                                    this.rt2 = 'null'
                                }
                                if(item.live > 0 && this.rt2 != 'null'){
                                    if(this.rt2[0][1][1]){
                                        this.rati1 = this.rt2[0][1][1]
                                    }
                                    if(this.rt2[0][1][3]){
                                        this.rati3 = this.rt2[0][1][3]
                                    }
                                    if(this.rt2[0][1][5]){
                                        this.rati5 = this.rt2[0][1][5]
                                    }
                                    if(this.rt2[0][1][7]){
                                        this.rati7 = this.rt2[0][1][7]
                                    }
                                    if(this.rt2[0][1][8]){
                                        this.rati8 = this.rt2[0][1][8]
                                    }
                                    if(this.rt2[0][1][15]){
                                        this.rati15 = this.rt2[0][1][15]
                                    }
                                }
                            }else{
                                let val_1 = arrmatch.filter( product => {
                                    return product.eventId == dataImage[0].match_id
                                });

                                this.rt3 = val_1.slice(0,1)

                                const array_ratio = Object.entries(this.rt1).map((arr) => ({
                                    ...arr
                                }));

                                if(val_1 != ''){
                                    this.rt2 = array_ratio.filter(d => d[0] === val_1[0].eventId)
                                }else{
                                    this.rt2 = 'null'
                                }
                                if(item.live > 0 && this.rt2 != 'null'){
                                    if(this.rt2[0][1][1]){
                                        this.rati1 = this.rt2[0][1][1]
                                    }
                                    if(this.rt2[0][1][3]){
                                        this.rati3 = this.rt2[0][1][3]
                                    }
                                    if(this.rt2[0][1][5]){
                                        this.rati5 = this.rt2[0][1][5]
                                    }
                                    if(this.rt2[0][1][7]){
                                        this.rati7 = this.rt2[0][1][7]
                                    }
                                    if(this.rt2[0][1][8]){
                                        this.rati8 = this.rt2[0][1][8]
                                    }
                                    if(this.rt2[0][1][15]){
                                        this.rati15 = this.rt2[0][1][15]
                                    }
                                }
                            }
                        })
                    }, 50);
                    // setInterval(function () {
                        
                    // }, 60000);
                });
            })
        },
        getlocation () {
            this.axios.get('https://geolocation-db.com/json/').then((response) => {
                this.country = response.data.country_name
            })
        },
        viewCore(id, player) {
            this.players[id] = player;
            this.play(id)
        },
        viewCores(id, player) {
            this.players[id] = player;
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        playEnded() {
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        getVideohl () {
            this.axios.get(this.api_highlight).then((response) => {
                var hl = response.data.items
                const getRandom = Math.floor(Math.random() * hl.length);
                this.hlight = hl.find( el => getRandom == el.mid);
            })
        },
        getDataPlayMatch () {
            this.axios.get(this.api_listmatchlive).then((response) => {
                this.listhot = response.data.data.filter(d => d.hot === 1)
                this.listPlayMatch = response.data.data.filter(d => d.live === 1)
            })
        },
        retrieveAllCustomer (url) {
            this.axios.get(url).then(res => {
                if(res.status===200){
                    this.check = 1
                }
            }).catch(e => {
                this.checkes = e
                this.check = 0
            })
            return this.check;
        },
        getDataRatio () {
            let today = new Date();
            let date = today.getDate();
            if(date < 10){
                date = '0' + date
            }
            let month = today.getMonth()+1;
            let date_month = date + '_' + month;


            const form = new FormData();
            form.append('day', date_month);

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ

                this.dataRatio2 = response.data.oddData.live.event_map; // lấy trận
                this.dataRatio3 = response.data.oddData.live.odd_map;
                
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                const arr_match1 = Object.entries(this.dataRatio2).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio1 = Object.entries(this.dataRatio3).map((arrs) => ({
                    ...arrs
                }));

                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio

                this.data_name_ratio2 = arr_match1
                this.data_name_ratio3 = arr_ratio1
            })
        },
        GetDateRatio (event) {
            this.datechange = event.target.value
            if (this.datechange != this.date1 + '_' + this.month1) {
                $('.item_ratio_home_live').hide()
            }else{
                $('.item_ratio_home_live').show()
            }

            const form = new FormData();
            form.append('day', this.datechange);

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ
                
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));

                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio
            })
        },
        listIconChat (){
            this.axios.get(this.api_listicon,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                this.listIcon = response.data.data.data_sticker
            })
        },
        sendIconChat (event) {
            event.preventDefault();
            var dataImg = event.target.getAttribute('data_img')
            $('.dataImg_chat').val(dataImg)
            $('#chat-submit').trigger('click')
            $('.list_icon_chat').hide()
        },
        sendChat (e) {
            e.preventDefault();
            if(this.token == null){
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Bạn cần đăng nhập để chat',
                    showConfirmButton: false,
                    timer: 2000
                })
            }else{
                if(this.checkTime == 0){
                    var dataimage = $('.dataImg_chat').val()
                    this.axios.post(this.api_sendComment, {
                        id_match: this.idLv,
                        content: this.content_chat,
                        sticker: dataimage
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                    ).then(res => {
                        this.other = res
                        this.content_chat = ''
                        $('.dataImg_chat').val('');
                        this.checkTime = 1
                    }).catch(e => {
                        $('#loading_show').css('display', 'none')
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                    return false;
                }else{
                    this.content_chat = ''
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Bạn chỉ được gửi tối đa 1 tin nhắn trong 10 giây!',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return false;
                }
            }
        },
        sendChats () {
            if(this.token == null){
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Bạn cần đăng nhập để chat',
                    showConfirmButton: false,
                    timer: 2000
                })
            }else{
                if(this.checkTime == 0){
                    var dataimage = $('.dataImg_chat').val()
                    this.axios.post(this.api_sendComment, {
                        id_match: this.idLv,
                        content: this.content_chat,
                        sticker: dataimage
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }).then(res => {
                        this.other = res
                        this.content_chat = ''
                        $('.dataImg_chat').val('');
                        this.checkTime = 1
                    }).catch(e => {
                        $('#loading_show').css('display', 'none')
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                    return false;
                }else{
                    this.content_chat = ''
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Bạn chỉ được gửi tối đa 1 tin nhắn trong 10 giây!',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return false;
                }
            }
        },
        showIconChatLive () {
            $('.list_icon_chat').slideToggle();
        },
        clickToTop () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        convertDate (date) {
            var datecv = new Date(date);
            return datecv.toLocaleString()
        },
        onEditorReady(editor) {
            editor.editing.view.document.on('enter', (evt, data) => {
                if (data.isSoft) {
                    editor.execute('enter');
                } else {
                    setTimeout(() => {
                        this.sendChats()
                        editor.setData( '<p></p>' );
                        evt.stop();
                    }, 500);
                }
                    data.preventDefault();
                    evt.stop();
                    editor.editing.view.scrollToTheSelection();
            }, { priority: 'high' });
        },
        checkTeenMinute () {
            this.checkTime = 0
        },
        timeAgo(str_date) {
            var d = new Date();
            var UTCsecondsNow = (d.getTime() + d.getTimezoneOffset() * 60 * 1000);

            var date = new Date(str_date);
            var UTCseconds = (date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            var diff = UTCsecondsNow - UTCseconds;
            var tense = 'trước';
            if (diff < 0) {
                tense = 'sau';
                diff = Math.abs(diff);
            }
            if (diff === 0) return 0;
            var years = this.singular(Math.round(diff / 31557600000), 'năm');
            if (years)
            return years + tense;
            var months = this.singular(Math.round(diff / 2592000000), 'tháng');
            if (months)
            return months + tense;
            var days = this.singular(Math.round(diff / 86400000), 'ngày');
            if (days)
            return days + tense;
            var hours = this.singular(Math.round(diff / 3600000), 'giờ');
            if (hours)
            return hours + tense;
            var mins = this.singular(Math.round(diff / 60000), 'phút');
            if (mins)
            return mins + tense;
            var secs = this.singular(Math.round(diff / 1000), 'giây');
            if (secs){
                return secs + tense;
            }
            else return 'Vừa xong';
        },
        singular(num, str) {
            if (num >= 1) {
                return num + ' ' + str + ' ';
            }
            return '';
        },
        getlistApiMatch () {
            this.axios.get(this.api_matchCola).then((response) => {
                this.detailPlay = response.data.data.filter(d => d.result.match.id == this.idLv)

                this.listMatchApi = response.data.data.filter(d => d.result.match.matchStatus == 2)
                this.listMatchApiAll = response.data.data.slice(0,50)

                this.listNhaDai = response?.data?.data.filter(d => d.matchHot !== 0 && d.matchHot !== 1)
                this.ListHot = response?.data?.data.filter(d => d.matchHot == 1)
                this.ListMatchBlv = response?.data?.data.filter(d => d.matchHot == 0 || d.matchHot == 1)

                let today = new Date();
                var date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate()
                this.axios.get(this.api_ltds + '?date=' + date).then((response) => {
                    this.rt = response.data.odds
                    
                    let arr_odds = []
                    this.rt.forEach(item => {
                        arr_odds.push({...item})
                    })
                    
                    let arr_oddsend = []
                    for (let i = 0; i < arr_odds.length; i++) {
                        arr_odds[i].listMatches.forEach(item => {
                            arr_oddsend.push({...item})
                        })
                    }

                    let val_1 = arr_oddsend.filter( product => {
                        var nameHome = this.detailPlay[0].result.match.homeTeam.name.split(' ')
                        var nameAway = this.detailPlay[0].result.match.awayTeam.name.split(' ')
                        var checkHome = 0;
                        var checkAway = 0;
                        if(product.homeName.toLowerCase().includes(nameHome[0].toLowerCase()) && product.homeName.toLowerCase().includes(nameHome[1].toLowerCase())){
                            checkHome = 1
                        }
                        if(product.awayName.toLowerCase().includes(nameAway[0].toLowerCase()) && product.awayName.toLowerCase().includes(nameAway[1].toLowerCase())){
                            checkAway = 1
                        }
                        if( checkHome == 1 && checkAway == 1 ){
                            return true
                        }
                    });
                    
                    this.ratioMatchDetail = val_1
                });
                
            })
        },
        convertDatetime(d) {
            let gt7 = 7 * 3600
            let timeStamp_change = Number(d) + gt7
            return new Date(timeStamp_change * 1000).toISOString().slice(0, 19).replace('T', ' ')
        },
        replaceName (str) {
            return str.replaceAll('-', ' ')
        },
        convertDateStame (d) {
            let arr = d.split(' ')
            let ngaythang = arr[0]
            let arrNt = ngaythang.split('-')
            let giophut = arr[1]
            let arrGp = giophut.split(':')
            return arrNt[2] + '/' + arrNt[1] + ' ' + arrGp[0] + ':' + arrGp[1]
        },
        nextVideo () {
           this.checkClickAdv = 1 
        },
        getViewMatch () {
            let ss = sessionStorage.getItem("session")
            this.axios.get(this.api_view + '?id=' + this.idLv + '&session=' + ss).then((response) => {
                this.viewMatch = response.data.view
            })
        },
        splitString (str) {
            let strSplit = str.split(',')
            return strSplit
        },
        convertRatio (ratio) {
            let str = ratio;
            let int = Math.floor(ratio/1);
            let mod = ratio%1;
            if(mod > 0.5){
                str = (int+0.5)+'/'+(int+1);
            }
            else if(mod < 0.5){
                str = (int)+'/'+(int+0.5);
            }
            return str;
        },
        getDataListChat () {
            setTimeout(() => {
                var thiss = this
                let imgGift = require('@/assets/images/cic.gif')
                let imgtick = require('@/assets/images/icon/tick.png')
                $.ajax({
                    url: 'https://adminsa88.luamachtv.vip/api/chat/list-message',
                    type: "GET",
                    data: { id_match : this.idLv },
                    dataType: 'json',
                    success : function( response ) {
                        let reverseArray = response.data.reverse()
                        var id_append = $('input[name="id-chat"]').val();
                        $.each(reverseArray, function(){
                            // let checkname = this.fullname.substr(0, 3).toLowerCase();
                            // var class_blv = ''
                            // if(checkname == 'blv'){
                            //     class_blv = 'active_color'
                            // }else{
                            //     class_blv = ''
                            // }
                            if(this.sticker !== null){
                                this.stickerDV = `<div class="imgsticker"><img src="https://adminsa88.luamachtv.vip/${this.sticker}" alt=""></div>`;
                            }else{
                                this.stickerDV = `<div class="body content_chat_edit">${this.content}</div>`;
                            }
                            if(this.real_mess != 0){
                                this.people = `<img class="img_people" src="${imgtick}" alt="">`
                            }else{
                                this.people = ''
                            }
                            
                            if(this.level != 0){
                                this.levels = 'Lv' + this.level
                            }else{
                                this.levels = ''
                            }
                            this.str = `<div class="flex_chat_icon msg msg-${this.id}">
                                        <div class="img_random_chat">
                                            <img src="${imgGift}" alt="">
                                        </div>
                                        <div class="content_text_chats">
                                            <div class="name_time_chat">
                                                <div class="dtxt" title="23 Nov 22, 02:10 AM" data-time="${this.created_at}">${thiss.timeAgo(this.created_at)}</div>
                                                <div class="nme">${this.fullname} ${this.people} <span class="level level${this.levels}">${this.levels}</span></div>
                                            </div>
                                            ${this.stickerDV}
                                        </div>
                                    </div>`;
                            setTimeout(() => {
                                $(`#${id_append}`).append(this.str);
                                var hg = $('.chat-text').prop('scrollHeight');
                                $('.chat-text').animate({ scrollTop: hg+100 }, 5);
                            }, 500);
                        });
                    },
                });
            }, 1000);
        },
        getContentGhim (){
            this.axios.get(this.api_getGhim).then((response) => {
                this.ghimMess = response.data.data.list_data.pin_message
            })
        },
    }
}
</script>

<style scoped>
</style>
